import React, { Fragment, Suspense, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import {
  DatePicker,
  DateRangePicker,
  SelectPicker,
  Stack,
  TimePicker,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleUp,
  faBan,
  faClock,
  faLayerGroup,
  faMoneyBill1Wave,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
// import { BookingForm } from "../components/BookingForm";

import {
  Drawer,
  RadioGroup,
  Radio,
  ButtonToolbar,
  Button,
  IconButton,
  Placeholder,
} from "rsuite";
import { refetchCruiseDetails } from "../../data/groupBooking/cruiseDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import BookingSteps from "../../components/BookingSteps";
import HeaderCruiseDetails from "../../components/HeaderCruiseDetails";
import Fancybox from "../../components/fancybox-library/Fancybox";
import { fetchCruiseExtras } from "../../data/groupBooking/getExtraSlice";
import { fetchCheckOut } from "../../data/groupBooking/checkOutSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
const LazyShipImageFixed = React.lazy(() =>
  import("../../components/ShipFixedImage")
);
const BallonDetails = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const formatDate = (date) => {
    if (!date) return "";
    return format(date, "EEE d MMM"); // e.g., "Wed 2 Mar"
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const contentRef = useRef(null);

  const toggleText = () => {
    setIsExpanded((prev) => !prev);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Store which accordion item is currently open
  const [activeFaq, setActiveFaq] = useState(null);

  // Function to toggle accordion item
  const toggleAccordion = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };
  const faqItems = [
    {
      question: "What do I need to hire a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How old do I have to be to rent a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Can I book a rental car for someone else?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How do I find the cheapest car hire deal?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "What should I look for when choosing a car hire?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];

  useEffect(() => {
    const checkHeight = () => {
      if (contentRef.current) {
        const { scrollHeight, clientHeight } = contentRef.current;
        // Assuming line height is 1.5em and font size is 15px, adjust as necessary
        const lineHeight = 22.5; // 15px font size * 1.5 line height
        const threeLinesHeight = lineHeight * 3;

        setShowButton(scrollHeight > threeLinesHeight);
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight); // Recheck on window resize

    return () => {
      window.removeEventListener("resize", checkHeight); // Cleanup
    };
  }, [isExpanded]);
  const validationSchema = Yup.object({
    date: Yup.date().required("Date is required"),
    // timeSlot: Yup.string().required("Time slot is required"),

    // Add more validations as needed
  });
  const [passengers, setPassengers] = useState(
    Number(localStorage.getItem("passengers"))
  );
  const [foreigner, setForeigner] = useState(
    Number(localStorage.getItem("foreigner"))
  );
  const [egyptian, setEgyptian] = useState(
    Number(localStorage.getItem("egyptian"))
  );
  const dates = localStorage.getItem("date");
  useEffect(() => {
    setPassengers(foreigner + egyptian);
  }, [foreigner, egyptian]);

  const handleIncrement = (setter) => {
    setter((prev) => prev + 1);
  };
  // const handleChildIncrement = (setter) => {
  //   setter((prev) => (prev < 2 ? prev + 1 : prev));
  // };

  const handleDecrement = (setter) => {
    setter((prev) => (prev >= 0 ? prev - 1 : 0));
  };
  const formik = useFormik({
    initialValues: {
      date: new Date(),

      // Add more initial values as needed
    },
    validationSchema: validationSchema,
    onSubmit: (e) => {
      localStorage.setItem("passengers", passengers);
      localStorage.setItem("foreigner", foreigner);
      localStorage.setItem("egyptian", egyptian);
      localStorage.setItem("date", formatDate(formik.values.date));

      navigate("/checkout");
    },
  });

  return (
    <Fragment>
      <Helmet>
        <title>Ballon Details</title>{" "}
        <style type="text/css">
          {`
         .header .header-menu .menu li a{
         color:#051036;
         }
         .header .header-menu .menu .subnav>li>a {
         color:#051036 !important;
         }
         .is-sticky.bg-dark-1{
         background-color:#fff !important;
         }
         // .is-sticky .menu li a{
         // color:#fff !important;
         // }
         .ratio-15\:9::before{
         padding-bottom:0 !important;}
         .list-disc li::marker{
        
          color:#3554D1 !important;
         }
.searchMenu-loc__field{
width:100%}
         `}
        </style>
      </Helmet>

      <section className="row d-flex items-center  mt-0  ">
        <BookingSteps
          one={
            <div className="size-40 rounded-full flex-center bg-blue-1">
              <i className="icon-check text-16 text-white"></i>
            </div>
          }
          two={
            <div className="size-40 rounded-full flex-center bg-blue-1 text-16 text-white">
              2
            </div>
          }
          three={
            <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
              3
            </div>
          }
          four={
            <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
              4
            </div>
          }
        />
      </section>

      <section className="pt-40">
        <div className="container">
          <div className="row y-gap-15 justify-between items-end">
            <div className="col-auto">
              <h1 className="text-30 fw-600">Sunrise (First) Slot (Group)</h1>

              <div className="row x-gap-20 y-gap-20 items-center pt-10">
                <div className="col-auto">
                  <div className="row x-gap-10 items-center">
                    <div className="col-auto">
                      <div className="d-flex x-gap-5 items-center">
                        <i className="icon-calendar text-15 text-light-1"></i>
                        <div className="text-15 text-light-1">{dates}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="row x-gap-10 items-center">
                    <div className="col-auto">
                      <div className="d-flex x-gap-5 items-center">
                        <i className="icon-clock text-15 text-light-1"></i>
                        <div className="text-15 text-light-1">30 Minutes</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="row x-gap-10 items-center">
                    <div className="col-auto">
                      <div className="d-flex x-gap-5 items-center">
                        <FontAwesomeIcon icon={faUsersViewfinder} />
                        <div className="text-15 text-light-1">
                          {" "}
                          {passengers} Passengers{" "}
                          <span className="text-12 text-light-1">
                            ({foreigner > 0 && foreigner + " " + "Foreigner"}{" "}
                            {foreigner > 0 && egyptian > 0 && "&"}{" "}
                            {egyptian > 0 && egyptian + " " + "Egyptian"} )
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="row x-gap-10 y-gap-10">
                {/* <div className="col-auto">
                  <button className="button px-15 py-10 -blue-1">
                    <i className="icon-heart mr-10"></i>
                    Save
                  </button>
                </div> */}

                <div className="col-auto">
                  <button className="button px-15 py-10 -blue-1 bg-light-2">
                    <i className="icon-share mr-10"></i>
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-40 js-pin-container">
        <div className="container">
          <div className="row y-gap-30">
            <div className="col-lg-8">
              <div className="relative d-flex justify-center overflow-hidden gallery">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={1} // Equivalent to `data-slider-cols="base-1"`
                  navigation={{
                    prevEl: ".js-img-prev",
                    nextEl: ".js-img-next",
                  }}
                  className="swiper-wrapper"
                >
                  <SwiperSlide>
                    <img
                      src={"TITSolutions/img/backgrounds/1.jpg"}
                      alt={""}
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={"TITSolutions/img/backgrounds/2.jpg"}
                      alt={""}
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={"TITSolutions/img/backgrounds/3.jpg"}
                      alt={""}
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={"TITSolutions/img/backgrounds/4.jpg"}
                      alt={""}
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </SwiperSlide>
                </Swiper>

                {/* Navigation Buttons */}
                <div className="absolute h-100 col-11">
                  <button className="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-prev">
                    <i className="icon icon-chevron-left text-12"></i>
                  </button>
                  <button className="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-next">
                    <i className="icon icon-chevron-right text-12"></i>
                  </button>
                </div>

                {/* Gallery Link */}
                <div className="absolute h-full col-12 z-2 px-20 py-20 d-flex justify-end items-end">
                  <Fancybox>
                    <a
                      href={"TITSolutions/img/backgrounds/1.jpg"}
                      className="button -blue-1 px-24 py-15 bg-white text-dark-1"
                      data-fancybox="main-gallery"
                      aria-label="Gallery image" // Add this line
                    >
                      See All Photos
                    </a>

                    <a
                      href={"TITSolutions/img/backgrounds/2.jpg"}
                      className="d-none"
                      data-fancybox="main-gallery"
                      aria-label={`Gallery image`} // Add this line
                    ></a>
                    <a
                      href={"TITSolutions/img/backgrounds/3.jpg"}
                      className="d-none"
                      data-fancybox="main-gallery"
                      aria-label={`Gallery image`} // Add this line
                    ></a>
                  </Fancybox>
                </div>
              </div>
              <span id="overview"></span>
              <h3 className="text-22 fw-500 mt-30">Highlights</h3>

              <div className="row y-gap-30 justify-start pt-20">
                <div className="col-md-3 col-6">
                  <div className="d-flex  align-items-start">
                    <FontAwesomeIcon
                      icon={faClock}
                      className="text-22 text-blue-1 mr-10"
                    />
                    <div className="text-15 lh-15">
                      Flight Duration: <br /> 30 Minutes
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="d-flex align-items-start">
                    <svg
                      className="text-22 text-blue-1 mr-10"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 1000 1000"
                      enableBackground="new 0 0 1000 1000"
                      xmlSpace="preserve"
                      width="27"
                      fill="#BB4929"
                    >
                      <metadata>
                        Svg Vector Icons : http://www.onlinewebfonts.com/icon
                      </metadata>
                      <g>
                        <path d="M500,10c98.8,0,188.3,36.7,253.2,96.1c65,59.4,105.2,141.5,105.2,232.2c0,24.2-2.8,47.7-8,70.6c-5.1,22.7-12.6,44.6-21.8,65.6c-27.6,63.3-141.7,193-203.9,263.7c-7,8-13.1,14.3-18.2,19.4v39.7h-16.1h-20.6h-53.8H496h-55h-20.4h-15.8h-3.7v-42.7c-16.6-18.2-35-37.2-54-56.8C251.3,599.1,141.6,486,141.6,338.3c0-90.7,40.2-172.8,105.2-232.2C311.7,46.7,401.2,10,500,10L500,10z M582.1,860.8L565.3,990H448.1l-18.4-129.2H582.1L582.1,860.8z M499.8,737.1h61.8C620.6,499.2,672,277,609.2,71c-34-11.6-70.8-17.9-109.2-17.9c-1.4,0-2.9,0-4.3,0C471,280,497.2,509.1,499.8,737.1L499.8,737.1z M308.5,112.9c-115.9,141.7-108.8,299.5-7.9,469.7c26,30.2,53.8,58.8,80.5,86.3c17.5,18,34.5,35.5,50.5,53c-59.1-197.8-165.1-460.6-27.8-655C368.9,77.1,336.8,92.8,308.5,112.9L308.5,112.9z M727.2,142.4c60.1,136.2,29,314.3-43,458.4c45.1-54.3,87.5-109.6,101.9-142.5c8.3-18.9,14.9-38.4,19.4-58.4c4.5-19.8,6.9-40.4,6.9-61.7C812.4,262.5,780,193.5,727.2,142.4z"></path>
                      </g>
                    </svg>
                    <div className="text-15 lh-15">
                      Balloon Capacity: <br /> 28-Passenger
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="d-flex  align-items-start">
                    <FontAwesomeIcon
                      icon={faBan}
                      className="text-22 text-blue-1 mr-10"
                    />
                    <div className="text-15 lh-15 ">
                      Free cancellation <br />
                      <Link
                        to="#cancellation-policy"
                        className="text-blue-1 underline"
                      >
                        Learn more{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-top-light mt-40 mb-40"></div>

              <div className="row x-gap-40 y-gap-40">
                <div className="col-12">
                  <h3 className="text-22 fw-500">Overview </h3>

                  {/* Text content */}
                  <div
                    className={`text-dark-1 text-15 mt-20 ${
                      isExpanded ? "expanded" : "collapsed"
                    }`}
                    ref={contentRef}
                    style={{
                      maxHeight: isExpanded ? "none" : "3em", // 2 lines height (adjust based on line height)
                      overflow: "hidden",
                      lineHeight: "1.5em", // Adjust based on your design's line height
                    }}
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequuntur eaque vero quas doloribus quis delectus
                    veritatis quo praesentium impedit inventore esse, nesciunt
                    nemo! Dolorem sint ut unde sed quo suscipit, Lorem ipsum
                    dolor sit amet consectetur adipisicing elit. Consequuntur
                    eaque vero quas doloribus quis delectus veritatis quo
                    praesentium impedit inventore esse, nesciunt nemo! Dolorem
                    sint ut unde sed quo suscipit.
                  </div>
                  {/* Show More / Show Less button */}
                  {showButton && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleText();
                      }}
                      className="d-block text-14 text-blue-1 fw-500 underline mt-10"
                    >
                      {isExpanded ? "Show Less" : "Show More"}
                    </button>
                  )}
                </div>
                {/* <div className="mt-40 border-top-light">
                  <div className="row x-gap-40 y-gap-40 pt-20">
                    <div className="col-12">
                      <h3 className="text-22 fw-500">What's Included </h3>

                      <div className="row x-gap-40 y-gap-40 pt-20">
                        <div className="col-md-6">
                          <div className="text-dark-1 text-15">
                            <i className="icon-check text-success text-10 mr-10"></i>{" "}
                            Entry ticket to Potter Warner Bros Studio London
                          </div>
                          <div className="text-dark-1  text-15">
                            <i className="icon-check text-success text-10 mr-10"></i>{" "}
                            Return transfers in coach
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="text-dark-1 text-15">
                            <i className="icon-close text-danger text-10 mr-10"></i>{" "}
                            Food and drinks
                          </div>
                          <div className="text-dark-1 text-15">
                            <i className="icon-close text-danger text-10 mr-10"></i>{" "}
                            Gratuities
                          </div>
                          <div className="text-dark-1 text-15">
                            <i className="icon-close text-danger text-10 mr-10"></i>{" "}
                            Digital guide available 10 different languages at
                            cost
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <span id="cancellation-policy"></span>
                <div className="col-md-12 border-top-light pt-40 ">
                  <h5 className="text-16 fw-500">Cancellation policy </h5>
                  <div className="text-15 mt-10">
                    <ul className="list-disc text-15 mt-10">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Temporibus harum ad exercitationem ratione illo
                      consequatur, modi aspernatur numquam alias vero dolorem
                      mollitia at ea, delectus eum neque nisi cupiditate
                      laboriosam?
                    </ul>
                  </div>
                </div>

                <div className="col-12 border-top-light pt-40 ">
                  <h5 className="text-16 fw-500">Notes </h5>
                  <div className="text-dark-1 text-15">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Odit, placeat facilis aspernatur minima odio ipsa officiis,
                    tenetur nulla doloribus non impedit, cupiditate id tempora
                    iure dolorum autem aliquid nesciunt necessitatibus!
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="d-flex justify-end js-pin-content"
                data-offset="350"
              >
                <div className="px-30 py-30 rounded-4 border-light bg-white w-360 lg:w-full shadow-4">
                  <div className="d-flex justify-between items-center">
                    <div className="text-14 text-light-1">
                      <span className="text-20 fw-500 text-dark-1 ml-5">
                        Booking Details
                      </span>
                    </div>
                  </div>

                  <form
                    onSubmit={formik.handleSubmit}
                    className="row y-gap-20 pt-30"
                  >
                    {/* Dates  */}
                    <div className="col-12">
                      <div className="bg-white   border-bottom-light">
                        <div>
                          <p className="text-15 fw-500 ls-2 lh-16">Date:</p>
                          <DatePicker
                            value={formik.values.date}
                            onChange={(date) => {
                              formik.setFieldValue("date", date);
                              localStorage.setItem("date", formatDate(date));
                            }} // Update date
                            placeholder={"Select Date"}
                            className="text-15 text-light-1 ls-2 lh-16"
                            format="MM/dd/yyyy"
                            defaultValue={new Date()}
                            renderValue={(value) => {
                              return format(value, "EEE, d MMM");
                            }}
                            oneTap
                            shouldDisableDate={(date) => {
                              const today = new Date();
                              today.setHours(0, 0, 0, 0);
                              return date < today;
                            }}
                            renderCell={(date) => {
                              const day = date.getDate();
                              const month = date.getMonth();
                              const weekday = date.getDay();

                              return day;
                            }}
                          />
                        </div>
                      </div>

                      {formik.errors.date && (
                        <p className="text-danger text-14 text-center">
                          {formik.errors.date}
                        </p>
                      )}
                    </div>

                    <div className="col-12">
                      <div className="bg-white   ">
                        <div>
                          <p className="text-15 fw-500 ls-2 lh-16">
                            Passengers:
                          </p>
                          <div className={`searchMenu-guests  `}>
                            {" "}
                            <div className="bg-white px-30 py-30 rounded-4">
                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 fw-500">
                                    Foreigner
                                  </div>
                                </div>

                                <div className="col-auto">
                                  <div className="d-flex items-center js-counter">
                                    <button
                                      type="button"
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                      onClick={() =>
                                        foreigner > 0 &&
                                        passengers > 1 &&
                                        handleDecrement(setForeigner)
                                      }
                                    >
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15">{foreigner}</div>
                                    </div>

                                    <button
                                      type="button"
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                      onClick={() =>
                                        handleIncrement(setForeigner)
                                      }
                                    >
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="border-top-light mt-24 mb-24"></div>
                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 fw-500">Egyptian</div>
                                </div>

                                <div className="col-auto">
                                  <div className="d-flex items-center js-counter">
                                    <button
                                      type="button"
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                      onClick={() =>
                                        egyptian > 0 &&
                                        passengers > 1 &&
                                        handleDecrement(setEgyptian)
                                      }
                                    >
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15">{egyptian}</div>
                                    </div>

                                    <button
                                      type="button"
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                      onClick={() =>
                                        handleIncrement(setEgyptian)
                                      }
                                    >
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="text-16 text-center text-light-1">
                        <span className="text-30 text-primary fw-500 text-dark-1 ml-5">
                          {`${20 * passengers}`}${" "}
                        </span>
                        Total Price{" "}
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        className="button -dark-1 py-15 px-35 h-60 col-12 rounded-4 bg-blue-1 text-white"
                      >
                        Book Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="px-30 py-30 border-light ms-auto bg-white rounded-4 mt-30 justify-end js-pin-content w-360"
                data-offset="350"
              >
                <div className="text-18 text-primary fw-500">
                  <i className="icon-check  text-14 mr-10"></i> Included:
                </div>

                <div className="row x-gap-20 y-gap-20 pt-20">
                  <div className="col-auto">
                    <i className="icon-juice text-24 text-blue-1"></i>
                  </div>
                  <div className="col-auto">
                    <div className="text-15">Light Drink</div>
                  </div>
                </div>

                <div className="row x-gap-20 y-gap-20 pt-5">
                  <div className="col-auto">
                    <i className="icon-car text-24 text-blue-1"></i>
                  </div>
                  <div className="col-auto">
                    <div className="text-15">Pick-up and Drop-off Service</div>
                  </div>
                </div>

                <div className="row x-gap-20 y-gap-20 pt-5">
                  <div className="col-auto">
                    <i className="icon-shield  text-24 text-blue-1"></i>
                  </div>
                  <div className="col-auto">
                    <div className="text-15">Passenger Insurance</div>
                  </div>
                </div>

                <div className="row x-gap-20 y-gap-20 pt-5">
                  <div className="col-auto">
                    <i className="icon-heart text-24 text-blue-1"></i>
                  </div>
                  <div className="col-auto">
                    <div className="text-15">Flight Medal</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyShipImageFixed />
      </Suspense>

      <div id="itinerary"></div>
      {/* <div className="container mt-40 mb-40">
        <div className="border-top-light"></div>
      </div> */}

      <div id="facilities"></div>
      {/* <section className="mt-40">
        <div className="container">
          <div className="row x-gap-40 y-gap-40">
            <div className="col-12">
              <h3 className="text-22 fw-500">Facilities </h3>

              <div className="row x-gap-40 y-gap-40 pt-20">
                <div className="col-xl-4">
                  <div className="row y-gap-30">
                    <div className="col-12">
                      <div className="">
                        <div className="d-flex items-center text-16 fw-500">
                          <i className="icon-bathtub text-20 mr-10"></i>
                          Accommodation
                        </div>

                        <ul className="text-15 pt-10">
                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Towels
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Bath or shower
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Private bathroom
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Toilet
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Free toiletries
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Hairdryer
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Bath
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="">
                        <div className="d-flex items-center text-16 fw-500">
                          <i className="icon-bed text-20 mr-10"></i>
                          Sleeping
                        </div>

                        <ul className="text-15 pt-10">
                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Linen
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Wardrobe or closet
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="">
                        <div className="d-flex items-center text-16 fw-500">
                          <i className="icon-bell-ring text-20 mr-10"></i>
                          services
                        </div>

                        <ul className="text-15 pt-10">
                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Invoice provided
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Private check-in/check-out
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Luggage storage
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            24-hour front desk
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="row y-gap-30">
                    <div className="col-12">
                      <div className="">
                        <div className="d-flex items-center text-16 fw-500">
                          <i className="icon-tv text-20 mr-10"></i>
                          TV &amp; Technology
                        </div>

                        <ul className="text-15 pt-10">
                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Flat-screen TV
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Satellite channels
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Radio
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Telephone
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            TV
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="">
                        <div className="d-flex items-center text-16 fw-500">
                          <i className="icon-juice text-20 mr-10"></i>
                          Food &amp; Drink
                        </div>

                        <ul className="text-15 pt-10">
                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Kid meals
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Special diet menus ( request)
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Breakfast
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Bar
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Restaurant
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Tea/Coffee maker
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="">
                        <div className="d-flex items-center text-16 fw-500">
                          <i className="icon-washing-machine text-20 mr-10"></i>
                          services
                        </div>

                        <ul className="text-15 pt-10">
                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Daily housekeeping
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Dry cleaning
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Laundry
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="row y-gap-30">
                    <div className="col-12">
                      <div className="">
                        <div className="d-flex items-center text-16 fw-500">
                          <i className="icon-shield text-20 mr-10"></i>
                          Privacy &amp; security
                        </div>

                        <ul className="text-15 pt-10">
                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Fire extinguishers
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            CCTV in common
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Smoke alarms
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            24-hour security
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="">
                        <div className="d-flex items-center text-16 fw-500">
                          <i className="icon-city-2 text-20 mr-10"></i>
                          Others
                        </div>

                        <ul className="text-15 pt-10">
                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Hypoallergenic
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Non-smoking throughout
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Wake-up service
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Heating
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Packed lunches
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Carpeted
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Lift
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Fan
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Family rooms
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Facilities for disabled ______
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Ironing facilities
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Non-smoking rooms
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Iron
                          </li>

                          <li className="d-flex items-center">
                            <i className="icon-check text-10 mr-20"></i>
                            Room service
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <div className="container mt-40 mb-40">
        <div className="border-top-light"></div>
      </div> */}

      <section id="faq" className="pt-40 layout-pb-md">
        <div className="container">
          <div className="pt-40 border-top-light">
            <div className="row y-gap-20">
              <div className="col-lg-2">
                <h2 className="text-22 fw-500">
                  FAQs about <br /> Balloons
                </h2>
              </div>

              <div className="col-lg-10 ">
                <div className="accordion -simple row y-gap-20">
                  {faqItems.map((item, index) => (
                    <div className="col-12" key={index}>
                      <div className="accordion__item px-20 py-20 border-light rounded-4 ">
                        <div
                          className="accordion__button d-flex items-center "
                          onClick={() => toggleAccordion(index)}
                        >
                          <div className="accordion__icon size-40 flex-center bg-light-2 rounded-full mr-20">
                            <i
                              className={
                                activeFaq === index ? "icon-minus" : "icon-plus"
                              }
                            ></i>
                          </div>
                          <div className="button text-dark-1">
                            {item.question}
                          </div>
                        </div>
                        {activeFaq === index && (
                          <div
                            className="accordion__content"
                            style={{ maxHeight: "100%" }}
                          >
                            <div className="pt-20 pl-60">
                              <p className="text-15">{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default BallonDetails;
