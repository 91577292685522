import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "../url";

// Get initial state from localStorage if available
const getInitialState = () => {
  const savedPayload = localStorage.getItem("checkOut");
  return {
    checkOut: [],
    loading: false,
    error: null,
    lastCheckOutPayload: savedPayload ? JSON.parse(savedPayload) : null,
  };
};

const initialState = getInitialState();

export const fetchCheckOut = createAsyncThunk(
  "checkOut/fetchCheckOut",
  async (payload) => {
    // Store the payload in localStorage before making the request
    localStorage.setItem("checkOut", JSON.stringify(payload));

    const response = await fetch(`${Url}/be/getBookingForm`, {
      method: "POST",
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch extras");
    }
    return await response.json();
  }
);

export const refetchCheckOut = createAsyncThunk(
  "checkOut/refetchCheckOut",
  async (_, { getState }) => {
    const savedPayload = localStorage.getItem("checkOut");

    if (!savedPayload) {
      throw new Error("No previous cruise details found");
    }

    const payloadData = JSON.parse(savedPayload);
    console.log(payloadData);
    const response = await fetch(`${Url}/be/getBookingForm`, {
      method: "POST",
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payloadData),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch cruise details");
    }

    const data = await response.json();
    return data;
  }
);
const checkOutSlice = createSlice({
  name: "checkOut",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckOut.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCheckOut.fulfilled, (state, action) => {
        state.loading = false;
        state.checkOut = action.payload;
      })
      .addCase(fetchCheckOut.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(refetchCheckOut.pending, (state) => {
        state.loading = true;
      })
      .addCase(refetchCheckOut.fulfilled, (state, action) => {
        state.loading = false;
        state.checkOut = action.payload;
      })
      .addCase(refetchCheckOut.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default checkOutSlice.reducer;
