import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Original fetch thunk
export const fetchCruiseDetails = createAsyncThunk(
  "cruiseDetails/fetchDetails",
  async ({ OperatinID, payloadData }) => {
    try {
      // Store operation ID and payload for refetching
      localStorage.setItem("selectedCruiseId", OperatinID);
      localStorage.setItem("lastSearchPayload", JSON.stringify(payloadData));

      const response = await fetch(
        `https://api.nilecabins.info/be/getCruiseCabins?OperatinID=${OperatinID}`,
        {
          method: "POST",
          headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            durationID: payloadData.durationID,
            opreationDate: payloadData.opreationDate,
            numberRooms: payloadData.numberRooms,
            listPassengersRoomsRequests: [
              {
                adultNumber:
                  payloadData.listPassengersRoomsRequests[0].adultNumber,
                childNumber:
                  payloadData.listPassengersRoomsRequests[0].childNumber,
                childAges: payloadData.listPassengersRoomsRequests[0].childAges,
              },
            ],
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cruise details");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(error.message || "Failed to fetch cruise details");
    }
  }
);

// Refetch cruise details thunk
export const refetchCruiseDetails = createAsyncThunk(
  "cruiseDetails/refetchDetails",
  async (_, { getState }) => {
    const OperatinID = localStorage.getItem("selectedCruiseId");
    const savedPayload = localStorage.getItem("lastSearchPayload");

    if (!OperatinID || !savedPayload) {
      throw new Error("No previous cruise details found");
    }

    const payloadData = JSON.parse(savedPayload);

    const response = await fetch(
      `https://api.nilecabins.info/be/getCruiseCabins?OperatinID=${OperatinID}`,
      {
        method: "POST",
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          durationID: payloadData.durationID,
          opreationDate: payloadData.opreationDate,
          numberRooms: payloadData.numberRooms,
          listPassengersRoomsRequests: [
            {
              adultNumber:
                payloadData.listPassengersRoomsRequests[0].adultNumber,
              childNumber:
                payloadData.listPassengersRoomsRequests[0].childNumber,
              childAges: payloadData.listPassengersRoomsRequests[0].childAges,
            },
          ],
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch cruise details");
    }

    const data = await response.json();
    return data;
  }
);

const cruiseDetailsSlice = createSlice({
  name: "cruiseDetails",
  initialState: {
    cruiseData: null,
    loading: false,
    error: null,
    selectedCruiseId: localStorage.getItem("selectedCruiseId") || null,
  },
  reducers: {
    clearCruiseDetails: (state) => {
      state.cruiseData = null;
      state.error = null;
      state.selectedCruiseId = null;
      localStorage.removeItem("selectedCruiseId");
      localStorage.removeItem("lastSearchPayload");
    },
    setSelectedCruiseId: (state, action) => {
      state.selectedCruiseId = action.payload;
      localStorage.setItem("selectedCruiseId", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruiseDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCruiseDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.cruiseData = action.payload;
        state.error = null;
      })
      .addCase(fetchCruiseDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.cruiseData = null;
      })
      .addCase(refetchCruiseDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refetchCruiseDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.cruiseData = action.payload;
        state.error = null;
      })
      .addCase(refetchCruiseDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.cruiseData = null;
      });
  },
});

export const { clearCruiseDetails, setSelectedCruiseId } =
  cruiseDetailsSlice.actions;
export default cruiseDetailsSlice.reducer;
