import React, { Suspense, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import { BookingForm } from "../components/BookingForm";
const Home = () => {
  const navigate = useNavigate();
  const locations = [
    { name: "London", description: "Greater London, United Kingdom" },
    { name: "New York", description: "New York State, United States" },
    { name: "Paris", description: "France" },
    { name: "Madrid", description: "Spain" },
    { name: "Santorini", description: "Greece" },
    { name: "Egypt", description: "Cairo, Egypt" },
  ];
  const [isActiveDestination, setIsActiveDestination] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const searchMenuRef = useRef(null);

  // Toggle the active state on click
  const handleDestinationClick = () => {
    setIsActiveDestination(!isActiveDestination);
  };

  // Handle blur event
  const handleBlur = (event) => {
    if (
      searchMenuRef.current &&
      !searchMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveDestination(false);
    }
  };

  // Handle selection of a location
  const handleLocationSelect = (location) => {
    setSelectedLocation(location.name); // Set the selected location
    setSearchTerm(""); // Clear the search term
    setIsActiveDestination(false); // Close the dropdown after selection
  };

  // Filter locations based on search term
  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [value, setValue] = useState([null, null]);

  const handleChange = (dates) => {
    setValue(dates);
  };

  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(1);
  const [rooms, setRooms] = useState(1);

  const handleIncrement = (setter) => {
    setter((prev) => prev + 1);
  };

  const handleDecrement = (setter) => {
    setter((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const [isActiveGuest, setIsActiveGuest] = useState(false);

  const GuestMenuRef = useRef(null);

  // Toggle the active state on click
  const handleGuestClick = () => {
    setIsActiveGuest(!isActiveGuest);
  };
  const handleGuestBlur = (event) => {
    if (
      GuestMenuRef.current &&
      !GuestMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveGuest(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(date, "EEE d MMM"); // e.g., "Wed 2 Mar"
  };
  const disablePastDates = (date) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0); // Disable past dates
  };
  const destinations = [
    {
      city: "Aswan",
      imgSrc: "TITSolutions/img/destinations/3/1.png",
      properties: "4,090 properties",
    },
    {
      city: "Sharm Elshekh",
      imgSrc: "TITSolutions/img/destinations/3/2.png",
      properties: "4,090 properties",
    },
    {
      city: "Hurghada",
      imgSrc: "TITSolutions/img/destinations/3/3.png",
      properties: "4,090 properties",
    },
    {
      city: "Alexandria",
      imgSrc: "TITSolutions/img/destinations/3/4.png",
      properties: "4,090 properties",
    },
    {
      city: "Ain Soughna",
      imgSrc: "TITSolutions/img/destinations/3/5.png",
      properties: "4,090 properties",
    },
    {
      city: "Marsa Matrouh",
      imgSrc: "TITSolutions/img/destinations/3/6.png",
      properties: "4,090 properties",
    },
  ];
  const LazyShipImageFixed = React.lazy(() =>
    import("../components/ShipFixedImage")
  );
  return (
    <>
      {" "}
      <Helmet>
        <title>Hot Air Balloon</title>
      </Helmet>
      {/*banner */}
      <section className="masthead -type-1 z-5 ">
        <div className="masthead__bg  ">
          <img src="bg-1.jpg" alt="bg" className="js-lazy loaded" />
        </div>

        <div className="container">
          <div className="row justify-center">
            <div className="col-auto">
              <div className="text-center">
                <Fade
                  direction="down"
                  duration={1000}
                  delay={0}
                  damping={0.1}
                  cascade
                >
                  <h1 className="text-60 lg:text-40 md:text-30 text-white  ">
                    Experience the Magic of Hot Air Balloon
                  </h1>
                  <p className="text-white mt-15 ">
                    Soar above breathtaking landscapes and create unforgettable
                    memories with our exclusive hot air balloon adventures!
                  </p>{" "}
                </Fade>
              </div>
              {/* booking engine  */}
              <BookingForm />
            </div>
          </div>
        </div>
        <div className="masthead -type-9">
          <Link to="#secondSection" className="masthead__scroll">
            <div className="d-flex items-center">
              <div className="text-white lh-15 text-right mr-10">
                <div className="fw-500">Scroll Down </div>
                <div className="text-15">to discover more </div>
              </div>

              <div className="-icon">
                <div></div>
                <div></div>
              </div>
            </div>
          </Link>
        </div>
      </section>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyShipImageFixed />
      </Suspense>
      {/*Popular Destinations */}
      <section
        className="layout-pt-lg layout-pb-md relative z--1"
        id="secondSection"
      >
        <div className="container">
          <div className="row y-gap-20 justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title"> Bookings Types </h2>
                <p className="sectionTitle__text mt-5 sm:mt-0">
                  Discover the different types of hot air balloon rides for a
                  unique and thrilling experience!
                </p>
              </div>
            </div>
          </div>

          <div className="row x-gap-10 y-gap-10 pt-40 sm:pt-20">
            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center  flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <svg
                  className="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1000 1000"
                  enableBackground="new 0 0 1000 1000"
                  xmlSpace="preserve"
                  width="30"
                  height="30"
                  fill="#BB4929"
                >
                  <metadata>
                    Svg Vector Icons : http://www.onlinewebfonts.com/icon
                  </metadata>
                  <g>
                    <path d="M500,10c98.8,0,188.3,36.7,253.2,96.1c65,59.4,105.2,141.5,105.2,232.2c0,24.2-2.8,47.7-8,70.6c-5.1,22.7-12.6,44.6-21.8,65.6c-27.6,63.3-141.7,193-203.9,263.7c-7,8-13.1,14.3-18.2,19.4v39.7h-16.1h-20.6h-53.8H496h-55h-20.4h-15.8h-3.7v-42.7c-16.6-18.2-35-37.2-54-56.8C251.3,599.1,141.6,486,141.6,338.3c0-90.7,40.2-172.8,105.2-232.2C311.7,46.7,401.2,10,500,10L500,10z M582.1,860.8L565.3,990H448.1l-18.4-129.2H582.1L582.1,860.8z M499.8,737.1h61.8C620.6,499.2,672,277,609.2,71c-34-11.6-70.8-17.9-109.2-17.9c-1.4,0-2.9,0-4.3,0C471,280,497.2,509.1,499.8,737.1L499.8,737.1z M308.5,112.9c-115.9,141.7-108.8,299.5-7.9,469.7c26,30.2,53.8,58.8,80.5,86.3c17.5,18,34.5,35.5,50.5,53c-59.1-197.8-165.1-460.6-27.8-655C368.9,77.1,336.8,92.8,308.5,112.9L308.5,112.9z M727.2,142.4c60.1,136.2,29,314.3-43,458.4c45.1-54.3,87.5-109.6,101.9-142.5c8.3-18.9,14.9-38.4,19.4-58.4c4.5-19.8,6.9-40.4,6.9-61.7C812.4,262.5,780,193.5,727.2,142.4z"></path>
                  </g>
                </svg>

                <div className="ml-10 text-center sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Individual Booking</h4>
                  <p className="text-14">
                    {" "}
                    Solo travelers looking to join a shared hot air balloon
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center  flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <svg
                  className="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1000 1000"
                  enableBackground="new 0 0 1000 1000"
                  xmlSpace="preserve"
                  width="30"
                  height="30"
                  fill="#BB4929"
                >
                  <metadata>
                    Svg Vector Icons : http://www.onlinewebfonts.com/icon
                  </metadata>
                  <g>
                    <path d="M500,10c98.8,0,188.3,36.7,253.2,96.1c65,59.4,105.2,141.5,105.2,232.2c0,24.2-2.8,47.7-8,70.6c-5.1,22.7-12.6,44.6-21.8,65.6c-27.6,63.3-141.7,193-203.9,263.7c-7,8-13.1,14.3-18.2,19.4v39.7h-16.1h-20.6h-53.8H496h-55h-20.4h-15.8h-3.7v-42.7c-16.6-18.2-35-37.2-54-56.8C251.3,599.1,141.6,486,141.6,338.3c0-90.7,40.2-172.8,105.2-232.2C311.7,46.7,401.2,10,500,10L500,10z M582.1,860.8L565.3,990H448.1l-18.4-129.2H582.1L582.1,860.8z M499.8,737.1h61.8C620.6,499.2,672,277,609.2,71c-34-11.6-70.8-17.9-109.2-17.9c-1.4,0-2.9,0-4.3,0C471,280,497.2,509.1,499.8,737.1L499.8,737.1z M308.5,112.9c-115.9,141.7-108.8,299.5-7.9,469.7c26,30.2,53.8,58.8,80.5,86.3c17.5,18,34.5,35.5,50.5,53c-59.1-197.8-165.1-460.6-27.8-655C368.9,77.1,336.8,92.8,308.5,112.9L308.5,112.9z M727.2,142.4c60.1,136.2,29,314.3-43,458.4c45.1-54.3,87.5-109.6,101.9-142.5c8.3-18.9,14.9-38.4,19.4-58.4c4.5-19.8,6.9-40.4,6.9-61.7C812.4,262.5,780,193.5,727.2,142.4z"></path>
                  </g>
                </svg>

                <div className="ml-10 text-center sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Group Booking</h4>
                  <p className="text-14">
                    {" "}
                    Families or Friends booking together for a shared adventure.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center  flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <svg
                  className="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1000 1000"
                  enableBackground="new 0 0 1000 1000"
                  xmlSpace="preserve"
                  width="30"
                  height="30"
                  fill="#BB4929"
                >
                  <metadata>
                    Svg Vector Icons : http://www.onlinewebfonts.com/icon
                  </metadata>
                  <g>
                    <path d="M500,10c98.8,0,188.3,36.7,253.2,96.1c65,59.4,105.2,141.5,105.2,232.2c0,24.2-2.8,47.7-8,70.6c-5.1,22.7-12.6,44.6-21.8,65.6c-27.6,63.3-141.7,193-203.9,263.7c-7,8-13.1,14.3-18.2,19.4v39.7h-16.1h-20.6h-53.8H496h-55h-20.4h-15.8h-3.7v-42.7c-16.6-18.2-35-37.2-54-56.8C251.3,599.1,141.6,486,141.6,338.3c0-90.7,40.2-172.8,105.2-232.2C311.7,46.7,401.2,10,500,10L500,10z M582.1,860.8L565.3,990H448.1l-18.4-129.2H582.1L582.1,860.8z M499.8,737.1h61.8C620.6,499.2,672,277,609.2,71c-34-11.6-70.8-17.9-109.2-17.9c-1.4,0-2.9,0-4.3,0C471,280,497.2,509.1,499.8,737.1L499.8,737.1z M308.5,112.9c-115.9,141.7-108.8,299.5-7.9,469.7c26,30.2,53.8,58.8,80.5,86.3c17.5,18,34.5,35.5,50.5,53c-59.1-197.8-165.1-460.6-27.8-655C368.9,77.1,336.8,92.8,308.5,112.9L308.5,112.9z M727.2,142.4c60.1,136.2,29,314.3-43,458.4c45.1-54.3,87.5-109.6,101.9-142.5c8.3-18.9,14.9-38.4,19.4-58.4c4.5-19.8,6.9-40.4,6.9-61.7C812.4,262.5,780,193.5,727.2,142.4z"></path>
                  </g>
                </svg>

                <div className="ml-10 text-center sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Private Booking</h4>
                  <p className="text-14"> Your group or special event.</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Featured Cruise Deals */}
      <section className="layout-pt-md layout-pb-md">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title"> Trips </h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  Explore our hot air balloon trips
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center y-gap-20 pt-40">
            <div className="col-lg-4 col-sm-6">
              <div className="ctaCard -type-1 rounded-4 ">
                <div className="ctaCard__image ratio ratio-41:45">
                  <img
                    className="img-ratio js-lazy"
                    src="TITSolutions/img/backgrounds/1.jpg"
                    alt="offer"
                  />
                </div>

                <div className="ctaCard__content py-50 px-50 lg:py-30 lg:px-30">
                  <h4 className="text-30 lg:text-24 text-white">
                    Sunrise Trips
                  </h4>

                  <div className="text-15 fw-500 text-white mt-20">
                    Early morning flights to enjoy the breathtaking sunrise from
                    above, often paired with a light breakfast.
                  </div>
                  <div className="d-inline-block mt-30">
                    <Link
                      to="#."
                      className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1"
                    >
                      Book Now{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="ctaCard -type-1 rounded-4 ">
                <div className="ctaCard__image ratio ratio-41:45">
                  <img
                    className="img-ratio js-lazy"
                    src="sunset.jpeg"
                    alt="offer"
                  />
                </div>

                <div className="ctaCard__content py-50 px-50 lg:py-30 lg:px-30">
                  <h4 className="text-30 lg:text-24 text-white">
                    Sunset Trips
                  </h4>
                  <div className="text-15 fw-500 text-white mt-20">
                    Evening rides timed with the sunset, creating a romantic and
                    serene experience, with optional refreshments.
                  </div>
                  <div className="d-inline-block mt-30">
                    <Link
                      to="#."
                      className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1"
                    >
                      Book Now{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="ctaCard -type-1 rounded-4 ">
                <div className="ctaCard__image ratio ratio-41:45">
                  <img
                    className="img-ratio js-lazy"
                    src="couple.jpeg"
                    alt="offer"
                  />
                </div>

                <div className="ctaCard__content py-50 px-50 lg:py-30 lg:px-30">
                  <h4 className="text-30 lg:text-24 text-white">
                    Private Couples’ Trips
                  </h4>{" "}
                  <div className="text-15 fw-500 text-white mt-20">
                    Exclusive hot air balloon rides for couples, perfect for
                    anniversaries, proposals, or romantic celebrations.
                  </div>
                  <div className="d-inline-block mt-30">
                    <Link
                      to="#."
                      className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1"
                    >
                      Book Now{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-lg-6">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Special Offers</h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  Book a hot air balloon ride with us and experience the thrill
                  of soaring above the ground. Our expert pilots will guide you
                  through a safe and unforgettable journey, offering
                  breathtaking views and creating lifelong memories.
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-30 pt-40">
            <div className="col-lg-6">
              <Link to="#." className="row y-gap-20 items-center">
                <div className="col-auto">
                  <img
                    className="size-250 rounded-4"
                    src="TITSolutions/img/backgrounds/3.jpg"
                    alt=""
                  />
                </div>

                <div className="col">
                  <div className="text-15 text-light-1">Until April, 2025 </div>
                  <h4 className="text-22 fw-600 text-dark-1 mt-10">
                    Experience the Magic of Hot Air Balloon Rides in Egypt
                  </h4>
                  <p className="mt-10">
                    Soar above the stunning landscapes of Egypt and enjoy a
                    unique perspective of the ancient wonders. Book now for an
                    unforgettable adventure!
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-6">
              <Link to="#." className="row y-gap-20 items-center">
                <div className="col-auto">
                  <img
                    className="size-250 rounded-4"
                    src="TITSolutions/img/backgrounds/4.jpg"
                    alt=""
                  />
                </div>

                <div className="col">
                  <div className="text-15 text-light-1">Until July, 2025 </div>
                  <h4 className="text-22 fw-600 text-dark-1 mt-10">
                    Hot Air Balloon Ride over the Pyramids
                  </h4>
                  <p className="mt-10">
                    Experience the breathtaking views of the Pyramids of Giza
                    from above. Book your adventure now for an unforgettable
                    journey!
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
