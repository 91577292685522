import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Get initial state from localStorage if available
const getInitialState = () => {
  const savedPayload = localStorage.getItem("lastCruiseExtrasPayload");
  return {
    extras: [],
    selectedExtras: [],
    loading: false,
    error: null,
    lastCruiseExtrasPayload: savedPayload ? JSON.parse(savedPayload) : null,
  };
};

const initialState = getInitialState();

export const fetchCruiseExtras = createAsyncThunk(
  "cruiseExtras/fetchCruiseExtras",
  async (payload) => {
    // Store the payload in localStorage before making the request
    localStorage.setItem("lastCruiseExtrasPayload", JSON.stringify(payload));

    const response = await fetch(
      "https://api.nilecabins.info/be/getCruiseExtra",
      {
        method: "POST",
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch extras");
    }
    return await response.json();
  }
);

export const refetchCruiseExtras = createAsyncThunk(
  "cruiseExtras/refetchCruiseExtras",
  async (_, { getState }) => {
    const savedPayload = localStorage.getItem("lastCruiseExtrasPayload");

    if (!savedPayload) {
      throw new Error("No previous cruise details found");
    }

    const payloadData = JSON.parse(savedPayload);
    console.log(payloadData);
    const response = await fetch(
      `https://api.nilecabins.info/be/getCruiseExtra`,
      {
        method: "POST",
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadData),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch cruise details");
    }

    const data = await response.json();
    return data;
  }
);
const cruiseExtrasSlice = createSlice({
  name: "cruiseExtras",
  initialState,
  reducers: {
    selectExtra: (state, action) => {
      const selected = state.extras.response?.cruiseExtras?.find(
        (extra) => extra.cruiseExtraID === action.payload.cruiseExtraID
      );

      if (selected) {
        if (action.payload.checked) {
          // Add to selectedExtra array if not already present
          if (
            !state.selectedExtras.some(
              (extra) => extra.cruiseExtraID === selected.cruiseExtraID
            )
          ) {
            state.selectedExtras.push(selected);
          }
        } else {
          // Remove from selectedExtras array if present
          state.selectedExtras = state.selectedExtras.filter(
            (extra) => extra.cruiseExtraID !== selected.cruiseExtraID
          );
        }
      }
    },
    removeExtra: (state, action) => {
      // Remove the extra from selectedExtras array
      state.selectedExtras = state.selectedExtras.filter(
        (extra) => extra.cruiseExtraID !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruiseExtras.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCruiseExtras.fulfilled, (state, action) => {
        state.loading = false;
        state.extras = action.payload;
      })
      .addCase(fetchCruiseExtras.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(refetchCruiseExtras.pending, (state) => {
        state.loading = true;
      })
      .addCase(refetchCruiseExtras.fulfilled, (state, action) => {
        state.loading = false;
        state.extras = action.payload;
      })
      .addCase(refetchCruiseExtras.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectExtra, removeExtra } = cruiseExtrasSlice.actions;
export default cruiseExtrasSlice.reducer;
