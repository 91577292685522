import { Tooltip } from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

const BookingSteps = ({
  one,
  two,
  three,
  four,

  inStepThree,
  inStepFour,
}) => {
  const navigate = useNavigate();
  const { extras } = useSelector((state) => state.cruiseExtras);
  return (
    <Fragment>
      {" "}
      <div className="  mt-0 py-40 bg-light-2 border-top-light">
        <div className="container d-flex justify-content-between m-auto px-1    y-gap-30 items-center ">
          <div className="col-auto pe-1">
            <Tooltip arrow title="Return To Search">
              <div
                className="d-flex items-center"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
                onMouseEnter={(e) => {
                  e.target.style.color = "#BB4929";
                }}
              >
                {one}
                <div className="text-18 fw-500 ml-10">Choose Trip</div>
              </div>
            </Tooltip>
          </div>{" "}
          <div className="col px-1">
            <div className="w-full h-1 bg-border"></div>
          </div>
          <div className="col-auto  px-1">
            <div
              className="d-flex items-center"
              onClick={() => navigate(inStepThree && "/balloon-details")}
              style={{ cursor: inStepThree && "pointer" }}
              onMouseEnter={(e) => {
                e.target.style.color = inStepThree && "#BB4929";
              }}
            >
              {two}
              <div className="text-18 fw-500 ml-10">
                {inStepThree ? (
                  <Fragment>
                    {" "}
                    <Tooltip arrow title="Return To Choose Cabin">
                      Choose Balloon{" "}
                    </Tooltip>
                  </Fragment>
                ) : (
                  "Choose Balloon"
                )}
              </div>
            </div>
          </div>
          <div className="col px-1">
            <div className="w-full h-1 bg-border"></div>
          </div>
          <div className="col-auto px-1">
            <div className="d-flex items-center">
              {three}
              <div className="text-18 fw-500 ml-10">Checkout </div>
            </div>
          </div>
          <div className="col px-1">
            <div className="w-full h-1 bg-border"></div>
          </div>
          <div className="col-auto px-1">
            <div className="d-flex items-center">
              {four}
              <div className="text-18 fw-500 ml-10">Booking Completed! </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BookingSteps;
