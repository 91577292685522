import React, { Fragment, Suspense, useRef, useState } from "react";
import { Bounce, Fade, Hinge, JackInTheBox, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";

import { BookingForm } from "../../components/BookingForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMoneyBill } from "@fortawesome/free-solid-svg-icons";

const LazyShipImageFixed = React.lazy(() =>
  import("../../components/ShipFixedImageList")
);
const BallonListing = () => {
  const passengers = localStorage.getItem("passengers");
  const foreigner = localStorage.getItem("foreigner");
  const egyptian = localStorage.getItem("egyptian");

  return (
    <Fragment>
      <Helmet>
        <title>Balloons List</title>
        <style type="text/css">
          {`
          footer{display:none;}
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            // .is-sticky.bg-dark-1{
            // background-color:#fff !important;
            // }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\\:9::before{
            padding-bottom:0 !important;}
              .description {
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  .collapsed {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;  
  }
  .expanded {
   display: block;
   -webkit-line-clamp: unset;
   -webkit-box-orient: unset;
   overflow: unset;
   text-overflow:unset; 
  }
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="section-bg pt-40 pb-40 relative z-0">
        <div className="section-bg__item col-12 z-0">
          <img
            src="titsolutions/img/misc/bg-1.png"
            alt=" "
            className="w-full h-full object-cover"
          />
        </div>

        <div className="container">
          <div className="row pt-90">
            <div className="col-12">
              <div className="text-center">
                <Fade direction="down" duration={1000} delay={0}>
                  <h1 className="text-30 fw-600" style={{ color: "#fff" }}>
                    {" "}
                    HOT AIR BALLOON
                  </h1>
                </Fade>
              </div>
              <div className=" pb-lg-5 mb-lg-5 ">
                <div className="container mb-5 pb-5">
                  {" "}
                  <BookingForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* booking engine  */}
      <section className="layout-pt-md layout-pb-lg">
        <div className="container-fluid px-50">
          <div className="row y-gap-30">
            <div className="col-xl-3 h-100" style={{ position: "relative" }}>
              <Suspense fallback={<div>Loading...</div>}>
                <LazyShipImageFixed />
              </Suspense>
            </div>

            <div className="col-xl-9">
              <div className="row y-gap-10 items-center justify-between">
                <div className="col-auto">
                  <div className="text-18">
                    <span className="fw-500">Available Flights (3)</span>
                  </div>
                </div>

                <div className="col-auto">
                  <div className="row x-gap-20 y-gap-20">
                    <div className="col-auto">
                      <button className="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1">
                        <i className="icon-up-down text-14 mr-10"></i>
                        Top picks your search
                      </button>
                    </div>

                    <div className="col-auto d-none lg:d-block">
                      <button
                        data-x-click="filterPopup"
                        className="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1"
                      >
                        <i className="icon-up-down text-14 mr-10"></i>
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-30"></div>

              {/* 1  */}
              <div className="row y-gap-30">
                <Fade
                  cascade
                  duration={1000}
                  delay={0}
                  direction="right"
                  triggerOnce
                  damping={0.1}
                >
                  <div className="col-12">
                    <div className="border-top-light py-20">
                      <div className="row align-content-center  x-gap-20 y-gap-20">
                        <div className="col-md-auto cruiseCard">
                          {/* Gallery  */}{" "}
                          <div
                            className="f54c1d70e0"
                            data-testid="gallery-ribbon"
                          >
                            <div className="aaa3a3be2e">Top Rated</div>
                            {/* <span className="cgh-ribbon__tails"></span> */}
                          </div>
                          <div
                            className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4"
                            style={{ height: "100%" }}
                          >
                            <div
                              className="cardImage__content"
                              style={{ height: "100%" }}
                            >
                              <Swiper
                                style={{ height: "100%" }}
                                modules={[Pagination, Navigation, Autoplay]}
                                autoplay={{
                                  delay: 3000,
                                  loop: false,
                                  delayTimeout: 1000,
                                  disableOnInteraction: false, // Continue autoplay after user interactions
                                }}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={{
                                  prevEl: ".js-prev-1", // Custom previous button class
                                  nextEl: ".js-next-1", // Custom next button class
                                }} // Enable custom navigation
                                pagination={{
                                  clickable: true,
                                  el: ".js-pagination", // Target custom pagination class
                                  renderBullet: (index, className) => {
                                    return `<div className="pagination__item ${className}" tabIndex="0" role="button" aria-label="Go to slide ${
                                      index + 1
                                    }"></div>`;
                                  },
                                }}
                              >
                                <SwiperSlide>
                                  <img
                                    style={{
                                      objectFit: "cover",
                                      objectPosition: "50%",
                                    }}
                                    className="col-12"
                                    src="sunrise/1.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                                <SwiperSlide>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="col-12"
                                    src="sunrise/2.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                                <SwiperSlide>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="col-12"
                                    src="sunrise/3.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                                <SwiperSlide>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="col-12"
                                    src="sunrise/4.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                              </Swiper>
                              {/* Custom Pagination Element */}
                              <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"></div>

                              {/* Custom Navigation Buttons */}
                              <div className="cardImage-slider__nav -prev">
                                <button
                                  className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev-1"
                                  tabIndex="0"
                                  aria-label="Previous slide"
                                >
                                  <i className="icon-chevron-left text-10"></i>
                                </button>
                              </div>
                              <div className="cardImage-slider__nav -next">
                                <button
                                  className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-next-1"
                                  tabIndex="0"
                                  aria-label="Next slide"
                                >
                                  <i className="icon-chevron-right text-10"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md">
                          <h3 className="text-18 lh-16 fw-500">
                            Sunrise (First) Slot <br />
                            <span className="text-14 text-secondary lh-11 fw-500">
                              (Private - Group)
                            </span>
                            <div className="d-inline-block ml-10">
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                            </div>
                          </h3>
                          <div className="row x-gap-10 y-gap-10 items-center   py-10">
                            <div className="col-auto">
                              <p className="text-14">
                                Early morning flights to enjoy the breathtaking
                                sunrise from above, often paired with a light
                                breakfast.
                              </p>
                            </div>
                          </div>
                          <div className="row justify-start pt-10">
                            <div className="col-md-4 col-6">
                              <div className="d-flex  align-items-center">
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="text-18 text-blue-1 mr-10"
                                />
                                <div className="text-14 lh-12">
                                  Takeoff time: <br />
                                  07:00 AM (30 Minutes)
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-6">
                              <div className="d-flex align-items-center">
                                <svg
                                  className="text-14 text-blue-1 mr-10"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 1000 1000"
                                  enableBackground="new 0 0 1000 1000"
                                  xmlSpace="preserve"
                                  width="27"
                                  fill="#BB4929"
                                >
                                  <metadata>
                                    Svg Vector Icons :
                                    http://www.onlinewebfonts.com/icon
                                  </metadata>
                                  <g>
                                    <path d="M500,10c98.8,0,188.3,36.7,253.2,96.1c65,59.4,105.2,141.5,105.2,232.2c0,24.2-2.8,47.7-8,70.6c-5.1,22.7-12.6,44.6-21.8,65.6c-27.6,63.3-141.7,193-203.9,263.7c-7,8-13.1,14.3-18.2,19.4v39.7h-16.1h-20.6h-53.8H496h-55h-20.4h-15.8h-3.7v-42.7c-16.6-18.2-35-37.2-54-56.8C251.3,599.1,141.6,486,141.6,338.3c0-90.7,40.2-172.8,105.2-232.2C311.7,46.7,401.2,10,500,10L500,10z M582.1,860.8L565.3,990H448.1l-18.4-129.2H582.1L582.1,860.8z M499.8,737.1h61.8C620.6,499.2,672,277,609.2,71c-34-11.6-70.8-17.9-109.2-17.9c-1.4,0-2.9,0-4.3,0C471,280,497.2,509.1,499.8,737.1L499.8,737.1z M308.5,112.9c-115.9,141.7-108.8,299.5-7.9,469.7c26,30.2,53.8,58.8,80.5,86.3c17.5,18,34.5,35.5,50.5,53c-59.1-197.8-165.1-460.6-27.8-655C368.9,77.1,336.8,92.8,308.5,112.9L308.5,112.9z M727.2,142.4c60.1,136.2,29,314.3-43,458.4c45.1-54.3,87.5-109.6,101.9-142.5c8.3-18.9,14.9-38.4,19.4-58.4c4.5-19.8,6.9-40.4,6.9-61.7C812.4,262.5,780,193.5,727.2,142.4z"></path>
                                  </g>
                                </svg>
                                <div className="text-14 lh-14">
                                  Balloon Capacity: <br /> 28-Passenger
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3 col-6">
                              <div className="d-flex  align-items-center">
                                <FontAwesomeIcon
                                  icon={faMoneyBill}
                                  className="text-22 text-blue-1 mr-10"
                                />
                                <div className="text-14 lh-14 ">
                                  Start From : <br />
                                  10$/pp
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="border-top-light mt-30 mb-20"></div>

                          <div className="row justify-center align-content-end x-gap-10 y-gap-10 pt-10">
                            <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Light Drink
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Passenger Insurance
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Flight Medal
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-auto text-right md:text-left">
                          <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-end">
                            <div className="col-auto">
                              <div className="text-14 lh-14 fw-500">
                                Exceptional
                              </div>
                              <div className="text-14 lh-14 text-light-1">
                                3,014 reviews
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="d-inline-block ml-10">
                                <i className="icon-star text-10 text-yellow-2"></i>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <div className="text-14 text-light-1 mt-50 md:mt-20">
                              <div className="text-22 text-primary lh-12 fw-600 mt-5">
                                $20
                              </div>
                              {passengers} Passengers
                              <br />
                              <sub>
                                (
                                {foreigner > 0 && foreigner + " " + "Foreigner"}{" "}
                                {foreigner > 0 && egyptian > 0 && "&"}{" "}
                                {egyptian > 0 && egyptian + " " + "Egyptian"} )
                              </sub>
                            </div>

                            <Link
                              to="/balloon-details"
                              className="button -md -dark-1 bg-blue-1 text-white mt-24"
                            >
                              Select{" "}
                              <div className="icon-arrow-top-right ml-15"></div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
              {/* 2 */}
              <div className="row y-gap-30">
                <Fade
                  cascade
                  duration={1000}
                  delay={0}
                  direction="right"
                  triggerOnce
                  damping={0.1}
                >
                  <div className="col-12">
                    <div className="border-top-light py-20">
                      <div className="row align-content-center  x-gap-20 y-gap-20">
                        <div className="col-md-auto cruiseCard">
                          {/* Gallery  */}{" "}
                          <div
                            className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4"
                            style={{ height: "100%" }}
                          >
                            <div
                              className="cardImage__content"
                              style={{ height: "100%" }}
                            >
                              <Swiper
                                style={{ height: "100%" }}
                                modules={[Pagination, Navigation, Autoplay]}
                                autoplay={{
                                  delay: 3000,
                                  loop: false,
                                  delayTimeout: 1000,
                                  disableOnInteraction: false, // Continue autoplay after user interactions
                                }}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={{
                                  prevEl: ".js-prev-2", // Custom previous button class
                                  nextEl: ".js-next-2", // Custom next button class
                                }} // Enable custom navigation
                                pagination={{
                                  clickable: true,
                                  el: ".js-pagination", // Target custom pagination class
                                  renderBullet: (index, className) => {
                                    return `<div className="pagination__item ${className}" tabIndex="0" role="button" aria-label="Go to slide ${
                                      index + 1
                                    }"></div>`;
                                  },
                                }}
                              >
                                <SwiperSlide>
                                  <img
                                    style={{
                                      objectFit: "cover",
                                      objectPosition: "50%",
                                    }}
                                    className="col-12"
                                    src="sunset/1.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                                <SwiperSlide>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="col-12"
                                    src="sunset/2.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                                <SwiperSlide>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="col-12"
                                    src="sunset/3.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                                <SwiperSlide>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="col-12"
                                    src="sunset/4.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                              </Swiper>
                              {/* Custom Pagination Element */}
                              <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"></div>

                              {/* Custom Navigation Buttons */}
                              <div className="cardImage-slider__nav -prev">
                                <button
                                  className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev-2"
                                  tabIndex="0"
                                  aria-label="Previous slide"
                                >
                                  <i className="icon-chevron-left text-10"></i>
                                </button>
                              </div>
                              <div className="cardImage-slider__nav -next">
                                <button
                                  className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-next-2"
                                  tabIndex="0"
                                  aria-label="Next slide"
                                >
                                  <i className="icon-chevron-right text-10"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md">
                          <h3 className="text-18 lh-16 fw-500">
                            Sunset / Morning Flight
                            <br />
                            <span className="text-14 text-secondary lh-11 fw-500">
                              (Private - Group)
                            </span>
                            <div className="d-inline-block ml-10">
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                            </div>
                          </h3>
                          <div className="row x-gap-10 y-gap-10 items-center   py-10">
                            <div className="col-auto">
                              <p className="text-14">
                                Evening rides timed with the sunset, creating a
                                romantic and serene experience, with optional
                                refreshments.
                              </p>
                            </div>
                          </div>
                          <div className="row justify-start pt-10">
                            <div className="col-md-4 col-6">
                              <div className="d-flex  align-items-center">
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="text-18 text-blue-1 mr-10"
                                />
                                <div className="text-14 lh-12">
                                  Takeoff time: <br />
                                  05:30 PM (30 Minutes)
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-6">
                              <div className="d-flex align-items-center">
                                <svg
                                  className="text-14 text-blue-1 mr-10"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 1000 1000"
                                  enableBackground="new 0 0 1000 1000"
                                  xmlSpace="preserve"
                                  width="27"
                                  fill="#BB4929"
                                >
                                  <metadata>
                                    Svg Vector Icons :
                                    http://www.onlinewebfonts.com/icon
                                  </metadata>
                                  <g>
                                    <path d="M500,10c98.8,0,188.3,36.7,253.2,96.1c65,59.4,105.2,141.5,105.2,232.2c0,24.2-2.8,47.7-8,70.6c-5.1,22.7-12.6,44.6-21.8,65.6c-27.6,63.3-141.7,193-203.9,263.7c-7,8-13.1,14.3-18.2,19.4v39.7h-16.1h-20.6h-53.8H496h-55h-20.4h-15.8h-3.7v-42.7c-16.6-18.2-35-37.2-54-56.8C251.3,599.1,141.6,486,141.6,338.3c0-90.7,40.2-172.8,105.2-232.2C311.7,46.7,401.2,10,500,10L500,10z M582.1,860.8L565.3,990H448.1l-18.4-129.2H582.1L582.1,860.8z M499.8,737.1h61.8C620.6,499.2,672,277,609.2,71c-34-11.6-70.8-17.9-109.2-17.9c-1.4,0-2.9,0-4.3,0C471,280,497.2,509.1,499.8,737.1L499.8,737.1z M308.5,112.9c-115.9,141.7-108.8,299.5-7.9,469.7c26,30.2,53.8,58.8,80.5,86.3c17.5,18,34.5,35.5,50.5,53c-59.1-197.8-165.1-460.6-27.8-655C368.9,77.1,336.8,92.8,308.5,112.9L308.5,112.9z M727.2,142.4c60.1,136.2,29,314.3-43,458.4c45.1-54.3,87.5-109.6,101.9-142.5c8.3-18.9,14.9-38.4,19.4-58.4c4.5-19.8,6.9-40.4,6.9-61.7C812.4,262.5,780,193.5,727.2,142.4z"></path>
                                  </g>
                                </svg>
                                <div className="text-14 lh-14">
                                  Balloon Capacity: <br /> 32 - Passenger
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3 col-6">
                              <div className="d-flex  align-items-center">
                                <FontAwesomeIcon
                                  icon={faMoneyBill}
                                  className="text-22 text-blue-1 mr-10"
                                />
                                <div className="text-14 lh-14 ">
                                  Start From : <br />
                                  10$/pp
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="border-top-light mt-30 mb-20"></div>
                          {/* 
                          <div className="text-14 text-green-2 lh-15 mt-10">
                            <div className="fw-500">Free cancellation</div>
                            <div className="">
                              You can cancel later, so lock in this great price
                              today.
                            </div>
                          </div> */}

                          <div className="row justify-center x-gap-10 y-gap-10 pt-10">
                            <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Light Drink
                              </div>
                            </div>

                            {/* <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Pick-up and Drop-off Service
                              </div>
                            </div> */}

                            <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Passenger Insurance
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Flight Medal
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-auto text-right md:text-left">
                          <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                            <div className="col-auto">
                              <div className="text-14 lh-14 fw-500">
                                Exceptional
                              </div>
                              <div className="text-14 lh-14 text-light-1">
                                3,014 reviews
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="d-inline-block ml-10">
                                <i className="icon-star text-10 text-yellow-2"></i>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <div className="text-14 text-light-1 mt-50 md:mt-20">
                              <div className="text-22 text-primary lh-12 fw-600 mt-5">
                                $20
                              </div>
                              {passengers} Passengers
                              <br />
                              <sub>
                                (
                                {foreigner > 0 && foreigner + " " + "Foreigner"}{" "}
                                {foreigner > 0 && egyptian > 0 && "&"}{" "}
                                {egyptian > 0 && egyptian + " " + "Egyptian"} )
                              </sub>
                            </div>

                            <Link
                              to="/balloon-details"
                              className="button -md -dark-1 bg-blue-1 text-white mt-24"
                            >
                              Select{" "}
                              <div className="icon-arrow-top-right ml-15"></div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
              {/* 3 */}
              <div className="row y-gap-30">
                <Fade
                  cascade
                  duration={1000}
                  delay={0}
                  direction="right"
                  triggerOnce
                  damping={0.1}
                >
                  <div className="col-12">
                    <div className="border-top-light py-20">
                      <div className="row align-content-center  x-gap-20 y-gap-20">
                        <div className="col-md-auto cruiseCard">
                          {/* Gallery  */}{" "}
                          <div
                            className="f54c1d70e0"
                            data-testid="gallery-ribbon"
                          >
                            <div className="aaa3a3be2e">Private</div>
                            {/* <span className="cgh-ribbon__tails"></span> */}
                          </div>
                          <div
                            className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4"
                            style={{ height: "100%" }}
                          >
                            <div
                              className="cardImage__content"
                              style={{ height: "100%" }}
                            >
                              <Swiper
                                style={{ height: "100%" }}
                                modules={[Pagination, Navigation, Autoplay]}
                                autoplay={{
                                  delay: 3000,
                                  loop: false,
                                  delayTimeout: 1000,
                                  disableOnInteraction: false,
                                }}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={{
                                  prevEl: ".js-prev",
                                  nextEl: ".js-next",
                                }}
                                pagination={{
                                  clickable: true,
                                  el: ".js-pagination",
                                  renderBullet: (index, className) => {
                                    return `<div className="pagination__item ${className}" tabIndex="0" role="button" aria-label="Go to slide ${
                                      index + 1
                                    }"></div>`;
                                  },
                                }}
                              >
                                <SwiperSlide>
                                  <img
                                    style={{
                                      objectFit: "cover",
                                      objectPosition: "50%",
                                    }}
                                    className="col-12"
                                    src="private/1.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                                <SwiperSlide>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="col-12"
                                    src="private/2.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                                <SwiperSlide>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="col-12"
                                    src="private/3.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                                <SwiperSlide>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    className="col-12"
                                    src="private/4.jpeg"
                                    alt=""
                                  />
                                </SwiperSlide>
                              </Swiper>
                              {/* Custom Pagination Element */}
                              <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"></div>

                              {/* Custom Navigation Buttons */}
                              <div className="cardImage-slider__nav -prev">
                                <button
                                  className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev"
                                  tabIndex="0"
                                  aria-label="Previous slide"
                                >
                                  <i className="icon-chevron-left text-10"></i>
                                </button>
                              </div>
                              <div className="cardImage-slider__nav -next">
                                <button
                                  className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-next"
                                  tabIndex="0"
                                  aria-label="Next slide"
                                >
                                  <i className="icon-chevron-right text-10"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md">
                          <h3 className="text-18 lh-16 fw-500">
                            Private Couples’ Flight
                            <br />
                            <span className="text-14 text-secondary lh-11 fw-500">
                              (Private)
                            </span>
                            <div className="d-inline-block ml-10">
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                              <i className="icon-star text-10 text-yellow-2"></i>
                            </div>
                          </h3>
                          <div className="row x-gap-10 y-gap-10 items-center   py-10">
                            <div className="col-auto">
                              <p className="text-14">
                                Exclusive hot air balloon rides for couples,
                                perfect for anniversaries, proposals, or
                                romantic celebrations.
                              </p>
                            </div>
                          </div>
                          <div className="row justify-start pt-10">
                            <div className="col-md-4 col-6">
                              <div className="d-flex  align-items-center">
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="text-18 text-blue-1 mr-10"
                                />
                                <div className="text-14 lh-12">
                                  Takeoff time: <br />
                                  optional (30 Minutes)
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-6">
                              <div className="d-flex align-items-center">
                                <svg
                                  className="text-14 text-blue-1 mr-10"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 1000 1000"
                                  enableBackground="new 0 0 1000 1000"
                                  xmlSpace="preserve"
                                  width="27"
                                  fill="#BB4929"
                                >
                                  <metadata>
                                    Svg Vector Icons :
                                    http://www.onlinewebfonts.com/icon
                                  </metadata>
                                  <g>
                                    <path d="M500,10c98.8,0,188.3,36.7,253.2,96.1c65,59.4,105.2,141.5,105.2,232.2c0,24.2-2.8,47.7-8,70.6c-5.1,22.7-12.6,44.6-21.8,65.6c-27.6,63.3-141.7,193-203.9,263.7c-7,8-13.1,14.3-18.2,19.4v39.7h-16.1h-20.6h-53.8H496h-55h-20.4h-15.8h-3.7v-42.7c-16.6-18.2-35-37.2-54-56.8C251.3,599.1,141.6,486,141.6,338.3c0-90.7,40.2-172.8,105.2-232.2C311.7,46.7,401.2,10,500,10L500,10z M582.1,860.8L565.3,990H448.1l-18.4-129.2H582.1L582.1,860.8z M499.8,737.1h61.8C620.6,499.2,672,277,609.2,71c-34-11.6-70.8-17.9-109.2-17.9c-1.4,0-2.9,0-4.3,0C471,280,497.2,509.1,499.8,737.1L499.8,737.1z M308.5,112.9c-115.9,141.7-108.8,299.5-7.9,469.7c26,30.2,53.8,58.8,80.5,86.3c17.5,18,34.5,35.5,50.5,53c-59.1-197.8-165.1-460.6-27.8-655C368.9,77.1,336.8,92.8,308.5,112.9L308.5,112.9z M727.2,142.4c60.1,136.2,29,314.3-43,458.4c45.1-54.3,87.5-109.6,101.9-142.5c8.3-18.9,14.9-38.4,19.4-58.4c4.5-19.8,6.9-40.4,6.9-61.7C812.4,262.5,780,193.5,727.2,142.4z"></path>
                                  </g>
                                </svg>
                                <div className="text-14 lh-14">
                                  Balloon Capacity: <br /> optional (max:28 )
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3 col-6">
                              <div className="d-flex  align-items-center">
                                <FontAwesomeIcon
                                  icon={faMoneyBill}
                                  className="text-22 text-blue-1 mr-10"
                                />
                                <div className="text-14 lh-14 ">$500</div>
                              </div>
                            </div>
                          </div>

                          <div className="border-top-light mt-30 mb-20"></div>
                          {/* 
                          <div className="text-14 text-green-2 lh-15 mt-10">
                            <div className="fw-500">Free cancellation</div>
                            <div className="">
                              You can cancel later, so lock in this great price
                              today.
                            </div>
                          </div> */}

                          <div className="row justify-center x-gap-10 y-gap-10 pt-10">
                            <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Light Drink
                              </div>
                            </div>

                            {/* <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Pick-up and Drop-off Service
                              </div>
                            </div> */}

                            <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Passenger Insurance
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                                Flight Medal
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-auto text-right md:text-left">
                          <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                            <div className="col-auto">
                              <div className="text-14 lh-14 fw-500">
                                Exceptional
                              </div>
                              <div className="text-14 lh-14 text-light-1">
                                3,014 reviews
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="d-inline-block ml-10">
                                <i className="icon-star text-10 text-yellow-2"></i>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <div className="text-14 text-light-1 mt-50 md:mt-20">
                              <div className="text-22 text-primary lh-12 fw-600 mt-5">
                                $500
                              </div>
                              {passengers} Passengers
                              <br />
                              <sub>
                                (
                                {foreigner > 0 && foreigner + " " + "Foreigner"}{" "}
                                {foreigner > 0 && egyptian > 0 && "&"}{" "}
                                {egyptian > 0 && egyptian + " " + "Egyptian"} )
                              </sub>
                            </div>

                            <Link
                              to="/balloon-details"
                              className="button -md -dark-1 bg-blue-1 text-white mt-24"
                            >
                              Select{" "}
                              <div className="icon-arrow-top-right ml-15"></div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="border-top-light   pt-30">
                <div className="row x-gap-10 y-gap-20 justify-between md:justify-center">
                  <div className="col-auto md:order-1">
                    <button className="button -blue-1 size-40 rounded-full border-light">
                      <i className="icon-chevron-left text-12"></i>
                    </button>
                  </div>

                  <div className="col-md-auto md:order-3">
                    <div className="row x-gap-20 y-gap-20 items-center md:d-none">
                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          1{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full bg-dark-1 text-white">
                          2{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          3{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full bg-light-2">
                          4{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          5{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          ...{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          20{" "}
                        </div>
                      </div>
                    </div>

                    <div className="row x-gap-10 y-gap-20 justify-center items-center d-none md:d-flex">
                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          1{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full bg-dark-1 text-white">
                          2{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          3{" "}
                        </div>
                      </div>
                    </div>

                    <div className="text-center mt-30 md:mt-10">
                      <div className="text-14 text-light-1">
                        3 – 10 of 300+ properties found{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-auto md:order-2">
                    <button className="button -blue-1 size-40 rounded-full border-light">
                      <i className="icon-chevron-right text-12"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default BallonListing;
