import { configureStore } from "@reduxjs/toolkit";
import durationsReducer from "./durationsSlice";
import itinerariesPageSliceReducer from "./itinerariesPageSlice";
import itineraryDetails from "./itineraryDetailsSlice";
import facilitiesReducer from "./facilitiesSlice";
import facilityDetails from "./facilityDetailsSlice";
import sightseeingReducer from "./sightseeingSlice";
import diningReducer from "./diningSlice";
import diningDetails from "./diningDetailsSlice";
import cruiseReducer from "./cruises-slice";
import groupReducer from "./Group-slice";
import roomsReducer from "./roomsSlice";
import roomDetails from "./roomDetailsSlice";
import faqReducer from "./faqSlice";
import galleryReducer from "./gallerySlice";
import contactReducer from "./contactSlice";
import customerDataSlice from "./postData";
import cabinBookingReducer from "./cabinBookingSlice";
import contactSubmitReducer from "./contactSubmitSlice";
import newsLetterReducer from "./newsLetterSlice";
import bookingCruisesGroupReducer from "./groupBooking/bookGroupSlice";
import cruisesListBookingReducer from "./groupBooking/cruiseListSlice";
import cruiseDetailsReducer from "./groupBooking/cruiseDetailsSlice";
import thanksData from "./getThanksPageSlice";
import checkOutReducer from "./groupBooking/checkOutSlice";
import languageReducer from "./languageSlice";
import nationalityReducer from "./groupBooking/nationalitySlice";
import extrasReducer from "./groupBooking/getExtraSlice";
import confirmationBookingReducer from "./groupBooking/confirmationBookingSlice";
export const store = configureStore({
  reducer: {
    language: languageReducer,
    group: groupReducer,
    cruises: cruiseReducer,
    durations: durationsReducer,
    itinerariesPage: itinerariesPageSliceReducer,
    itineraryDetails: itineraryDetails,
    sightseeing: sightseeingReducer,
    dining: diningReducer,
    diningDetails: diningDetails,
    facilities: facilitiesReducer,
    facilityDetails: facilityDetails,
    rooms: roomsReducer,
    roomDetails: roomDetails,
    faq: faqReducer,
    gallery: galleryReducer,
    contact: contactReducer,
    contactSubmit: contactSubmitReducer,
    cabins: cabinBookingReducer,
    customerData: customerDataSlice,
    newsLetter: newsLetterReducer,
    // group Booking
    bookingCruisesGroup: bookingCruisesGroupReducer,
    cruisesListBooking: cruisesListBookingReducer,
    cruiseDetails: cruiseDetailsReducer,
    thanksData: thanksData,
    checkOut: checkOutReducer,
    nationality: nationalityReducer,
    cruiseExtras: extrasReducer,
    confirmationBooking: confirmationBookingReducer,
  },
});
