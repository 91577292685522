import React, { Fragment, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider, useMediaQuery } from "@mui/material";
// import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";
import BookingSteps from "../components/BookingSteps";
import { useDispatch, useSelector } from "react-redux";
import { refetchCheckOut } from "../data/groupBooking/checkOutSlice";
import Loader from "./../Loader";
import { Button } from "react-bootstrap";

const Checkout = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passengers, setPassengers] = useState(
    Number(localStorage.getItem("passengers"))
  );
  const [foreigner, setForeigner] = useState(
    Number(localStorage.getItem("foreigner"))
  );
  const [egyptian, setEgyptian] = useState(
    Number(localStorage.getItem("egyptian"))
  );
  const dates = localStorage.getItem("date");
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/booking-confirmation");
  };
  return (
    <Fragment>
      {" "}
      <Helmet>
        <title>Checkout</title>{" "}
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="row d-flex items-center  mt-90  ">
        <BookingSteps
          inStepThree
          inStepFour
          one={
            <div className="size-40 rounded-full flex-center bg-blue-1  text-white">
              <i className="icon-check text-16 text-white"></i>
            </div>
          }
          two={
            <div className="size-40 rounded-full flex-center bg-blue-1 text-16 text-white">
              <i className="icon-check text-16 text-white"></i>
            </div>
          }
          three={
            <div className="size-40 rounded-full flex-center bg-blue-1 text-16 text-white">
              3
            </div>
          }
          four={
            <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 text-16  text-blue-1">
              4
            </div>
          }
        />
      </section>
      <section className="pt-40 layout-pb-md">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-7 col-lg-8">
              <h2 className="text-22 fw-500  ">Your Personal Information</h2>

              <form onSubmit={handleSubmit}>
                {" "}
                <div className="row x-gap-20 y-gap-20 pt-20">
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" className="form-control" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Full Name{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Email{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Phone Number{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Address line 1{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        State/Province/Region{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        ZIP code/Postal code{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-input ">
                      <textarea required="" rows="6"></textarea>
                      <label className="lh-1 text-16 text-light-1">
                        Special Requests{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full h-1 bg-border mt-40 mb-40"></div>
                <div className="col-12">
                  <div className="row y-gap-20 items-center justify-between">
                    {" "}
                    <div className="form-checkbox d-flex align-items-center">
                      <input type="checkbox" name="name" id="terms" />
                      <div className="form-checkbox__mark">
                        <div className="form-checkbox__icon icon-check"></div>
                      </div>
                      <div className="col-auto ms-2">
                        <label htmlFor="terms" className="text-14 text-light-1">
                          By proceeding this booking, I agree Booking Nile{" "}
                          <Link to="#.">Terms & Conditions</Link>.
                        </label>
                      </div>
                    </div>
                    <div className="col-auto">
                      <button
                        type="submit"
                        className="button h-60 px-24 -dark-1 bg-blue-1 text-white"
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-12 col-lg-4">
              <div className="">
                <div className="px-30 py-30 mt-20 border-light rounded-4">
                  <div className="row align-items-center x-gap-15 y-gap-20">
                    <div className="text-20 fw-500  mb-20">
                      Your Booking Details{" "}
                    </div>
                    <div className="col-auto">
                      <img
                        src="TITSolutions/img/backgrounds/1.jpg"
                        alt="balloon"
                        loading="lazy"
                        className="size-140 rounded-4 object-cover"
                      />
                    </div>

                    <div className="col">
                      <div className="d-flex x-gap-5 pb-10">
                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>
                      </div>

                      <div className="lh-17 fw-500">Sunrise (First) Slot</div>
                      <div className="text-14 lh-15 mt-5">Foreigner</div>

                      <div className="row x-gap-10 y-gap-10 items-center pt-10">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="size-30 flex-center bg-blue-1 rounded-4">
                              <div className="text-12 fw-600 text-white">
                                4.8
                              </div>
                            </div>

                            <div className="text-14 fw-500 ml-10">
                              Experiences
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-14">3,014 reviews</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-top-light mt-30 mb-20"></div>

                  <div className="row y-gap-20 justify-between">
                    <div className="col-auto">
                      <div className="text-15">Takeoff time</div>
                      <div className="fw-500">{dates}</div>
                      <div className="text-15 text-light-1">07:00</div>
                    </div>

                    <div className="col-auto md:d-none">
                      <div className="h-full w-1 bg-border"></div>
                    </div>

                    <div className="col-auto text-right md:text-left">
                      <div className="text-15">Landing time</div>
                      <div className="fw-500">{dates}</div>
                      <div className="text-15 text-light-1">07:30</div>
                    </div>
                  </div>
                  <div className="border-top-light mt-30 mb-20"></div>

                  <div className="row y-gap-20 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-15">You selected: </div>
                      <div className="fw-500">Group Trip</div>
                      <Link
                        to="/balloon-details"
                        className="text-15 text-blue-1 underline"
                      >
                        Change your selection{" "}
                      </Link>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-center text-light-1">
                        {" "}
                        {passengers} Passengers <br />
                        <span className="text-10 text-light-1">
                          ({foreigner > 0 && foreigner + " " + "Foreigner"}{" "}
                          {foreigner > 0 && egyptian > 0 && "&"}{" "}
                          {egyptian > 0 && egyptian + " " + "Egyptian"} )
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-30 py-30 border-light rounded-4 mt-30">
                  <div className="text-20 fw-500 mb-15">
                    Do you have a promo code?{" "}
                  </div>

                  <div className="form-input ">
                    <input type="text" required="" />
                    <label className="lh-1 text-16 text-light-1">
                      Enter promo code{" "}
                    </label>
                  </div>

                  <button className="button -outline-blue-1 text-blue-1 px-30 py-15 mt-20">
                    Apply{" "}
                  </button>
                </div>
                <div className="px-30 py-30 border-light rounded-4 mt-30">
                  <div className="text-20 fw-500 mb-20">Your price summary</div>

                  <div className="row y-gap-5 justify-between">
                    <div className="col-auto">
                      <div className="text-15">Sunrise (First) Slot</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">$20.00</div>
                    </div>
                  </div>

                  <div className="row y-gap-5 justify-between pt-5">
                    <div className="col-auto">
                      <div className="text-15">Taxes and fees</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">$674.47</div>
                    </div>
                  </div>

                  <div className="row y-gap-5 justify-between pt-5">
                    <div className="col-auto">
                      <div className="text-15">Booking fees</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">FREE</div>
                    </div>
                  </div>

                  <div className="px-20 py-20 bg-blue-2 rounded-4 mt-20">
                    <div className="row y-gap-5 justify-between">
                      <div className="col-auto">
                        <div className="text-18 lh-13 fw-500">Price</div>
                      </div>
                      <div className="col-auto">
                        <div className="text-18 lh-13 fw-500"> $20.674</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Checkout;
