import React, { Fragment, useEffect, useState } from "react";
import { Outlet, Link, NavLink, useLocation } from "react-router-dom";

import { Zoom } from "react-awesome-reveal";
import { subscribeNewsletter } from "../data/newsLetterSlice";
import { useDispatch, useSelector } from "react-redux";
import { error } from "jquery";
import { Alert, Snackbar, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEnvelope,
  faGlobeAfrica,
  faHeadset,
  faHome,
  faLocationDot,
  faMapLocationDot,
  faMoneyBill,
  faPhone,
  faQuestionCircle,
  faRoute,
  faSignsPost,
  faStar,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { setLanguage } from "../data/languageSlice";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faSquareInstagram,
  faSquareXTwitter,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Layout = () => {
  const currentYear = new Date().getFullYear();
  const [showButton, setShowButton] = useState(false);
  const language = useSelector((state) => state.language.language);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      // Convert 70vh to pixels
      const triggerHeight = window.innerHeight * 0.2;
      const triggerHeight2 = window.innerHeight * 0.1;

      if (window.scrollY > triggerHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
      if (window.scrollY > triggerHeight2) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Add event listener
    window.addEventListener("scroll", checkScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  const [isVisible, setIsVisible] = useState(false);
  const [view1, setView1] = useState(false);
  const [view2, setView2] = useState(false);
  const [view3, setView3] = useState(false);
  // Function to handle the scroll to top action
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to check scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Listen for scroll events
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { loadingNewsletter, errorNewsletter } = useSelector(
    (state) => state.newsLetter
  );
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the menu open and closed
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    dispatch(subscribeNewsletter(email));
  };
  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "ar" : "en";
    dispatch(setLanguage(newLanguage));
    window.location.pathname = `/${newLanguage}${window.location.pathname.substring(
      3
    )}`;
  };
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    // Check if the window's scroll position is greater than a certain value (e.g., 50px)
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [viewCurrency, setViewCurrency] = useState(true);
  const [viewLanguage, setViewLanguage] = useState(true);

  // mobile menu
  const [isMenuActive, setIsMenuActive] = useState(false);

  // Toggle menu handler
  const handleMenuToggle = () => {
    setIsMenuActive(!isMenuActive);
  };
  const [isDestinationsActive, setIsDestinationsActive] = useState(false);

  const handleDestinationsClick = (e) => {
    e.preventDefault();
    setIsDestinationsActive(!isDestinationsActive);
  };
  const [activeTab, setActiveTab] = useState(1); // Default to the first tab

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  return (
    <>
      {" "}
      <div
        className={`currencyMenu  js-currencyMenu ${
          viewCurrency && "is-hidden"
        }`}
      >
        <div
          className="currencyMenu__bg"
          data-x-click="currency"
          onClick={() => setViewCurrency(true)}
        ></div>

        <div className="currencyMenu__content bg-white rounded-4">
          <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
            <div className="text-20 fw-500 lh-15">Select your currency </div>
            <button className="pointer" onClick={() => setViewCurrency(true)}>
              <i className="icon-close"></i>
            </button>
          </div>

          <div className="modalGrid px-30 py-30 sm:px-15 sm:py-15">
            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`langMenu  js-langMenu ${viewLanguage && "is-hidden"}`}>
        <div
          className="langMenu__bg"
          onClick={() => setViewLanguage(true)}
        ></div>

        <div className="langMenu__content bg-white rounded-4">
          <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
            <div className="text-20 fw-500 lh-15">Select your language </div>
            <button className="pointer" onClick={() => setViewLanguage(true)}>
              <i className="icon-close"></i>
            </button>
          </div>

          <div className="modalGrid px-30 py-30 sm:px-15 sm:py-15">
            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header
        className={`header mt-20 -type-9 js-header ${
          isSticky ? "bg-dark-1 is-sticky" : ""
        }`}
      >
        <div className="header__container container">
          <div className="row justify-between items-center">
            <div className="col-auto">
              <div className="d-flex items-center">
                <Link
                  to="/"
                  className="header-logo mr-20"
                  data-x="header-logo"
                  data-x-toggle="is-logo-dark"
                >
                  {window.location.pathname.includes("/contact-us") ||
                  window.location.pathname.includes("/checkout") ||
                  window.location.pathname.includes("/add-extra") ? (
                    <img
                      src="logo1.png"
                      style={{ width: "auto" }}
                      alt="logo icon"
                    />
                  ) : (
                    <img
                      src="logo-2.png"
                      style={{ width: "auto" }}
                      alt="logo icon"
                    />
                  )}
                </Link>
              </div>
            </div>

            <div className="col-auto">
              <div className="d-flex items-center">
                <div className="d-flex items-center ml-24 is-menu-opened-hide md:d-none">
                  <div className="col-auto">
                    <div className="row x-gap-15 y-gap-15 items-center">
                      {/* <div className="col-auto">
                        <button
                          className="d-flex items-center text-14 text-white"
                          data-x-click="currency"
                          onClick={() => setViewCurrency(!viewCurrency)}
                        >
                          <span className="js-currencyMenu-mainTitle">
                            <FontAwesomeIcon icon={faMoneyBill} /> USD 
                          </span>
                          <i className="icon-chevron-sm-down text-7 ml-10"></i>
                        </button>
                      </div> */}
                      {/* <div className="col-auto">
                        <div className="w-1 h-20 bg-white-20"></div>
                      </div> */}
                      <div className="col-auto md:d-none">
                        <Link
                          to="tel:+201000972210"
                          className="text-12 text-white"
                        >
                          <FontAwesomeIcon icon={faPhone} /> +(20) 10 00000000{" "}
                        </Link>
                      </div>
                      <div className="col-auto md:d-none">
                        <div className="w-1 h-20 bg-white-20"></div>
                      </div>
                      <div className="col-auto">
                        <Link
                          to="mailto:info@example.com"
                          className="text-12 text-white"
                        >
                          <FontAwesomeIcon icon={faEnvelope} /> info@example.com{" "}
                        </Link>
                      </div>{" "}
                      <div className="col-auto">
                        <div className="w-1 h-20 bg-white-20"></div>
                      </div>{" "}
                      <div className="col-auto">
                        <button
                          className="d-flex items-center text-14 text-white"
                          onClick={() => setViewLanguage(!viewLanguage)}
                        >
                          <img
                            src="/titsolutions/img/general/lang.png"
                            width="16"
                            height="16"
                            loading="lazy"
                            alt="Language"
                            className="rounded-full mr-10"
                          />
                          <span className="js-language-mainTitle">EN </span>
                          <i className="icon-chevron-sm-down text-7 ml-15"></i>
                        </button>
                      </div>{" "}
                    </div>
                  </div>
                </div>

                <div
                  className="d-none xl:d-flex x-gap-20 items-center pl-20 text-white"
                  data-x="header-mobile-icons"
                  data-x-toggle="text-white"
                >
                  <button className="col-auto px-1 d-flex align-items-center bg-blue-1 px-2 py-2   mx-2">
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-12 text-white me-2"
                    />{" "}
                    <Link to="/booking-balloon" className="text-12 text-white">
                      Book Now{" "}
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Outlet />
      {/* <section
        className="layout-pt-md layout-pb-md bg-blue-1 newsletter  "
        style={{ position: "relative", overflow: "hidden" }}
      >
        <div className="container">
          <div className="row y-gap-30 justify-between items-center ">
            <div className="col-auto">
              <div className="d-flex y-gap-20 flex-wrap items-center">
                <div className="icon-newsletter text-60 sm:text-40 text-white"></div>

                <div className="ml-30">
                  <h4 className="text-26 text-white fw-600">
                    Your BookingNile.com Adventure Awaits!
                  </h4>
                  <div className="text-white">
                    Sign up and we'll sail you to the best deals on
                    unforgettable cruises!
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto" style={{ zIndex: 1 }}>
              <div className="single-field -w-410 d-flex x-gap-10 y-gap-20">
                <div>
                  <input
                    className="bg-white h-60"
                    type="text"
                    placeholder="Your Email"
                  />
                </div>

                <div>
                  <button className="button -md h-60 bg-yellow-1 text-dark-1">
                    Subscribe{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <footer
        className="footer -type-1 text-white bg-blue-1"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <div className="container">
          <div className="py-20 px-0 border-top-white-15 last-footer">
            <div className="row justify-content-between  ">
              <div className="col-lg-4">
                <div className="d-flex items-start">
                  © 2024 pyramidsballoons.com | All rights reserved.
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex items-center justify-center">
                  <div className="logo-animation">
                    {/* <img
                      src="logo-2.png"
                      style={{ width: "70px", height: "30px" }}
                      alt=""
                    /> */}
                    <h5> Hot Air Balloon</h5>
                  </div>
                  <style jsx>{`
                    .logo-animation {
                      animation: float 3s ease-in-out infinite,
                        wave 5s ease-in-out infinite;
                    }
                    @keyframes float {
                      0% {
                        transform: translateY(0);
                      }
                      50% {
                        transform: translateY(-15px);
                      }
                      100% {
                        transform: translateY(0);
                      }
                    }
                    @keyframes wave {
                      0% {
                        transform: translateX(0);
                      }
                      50% {
                        transform: translateX(15px);
                      }
                      100% {
                        transform: translateX(0);
                      }
                    }
                  `}</style>
                </div>
              </div>

              <div className="col-lg-4 ">
                <div className="text-right">
                  Powered By{" "}
                  <Link to="https://titegypt.com" target="_blank">
                    T.I.T Solutions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
