import React, { Fragment, useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
// import { fetchCabins } from "../../data/cabinBookingSlice";
// import Loader from "../../Loader";
// import Slider from "react-slick";
import "./cabins.css";
import { Accordion, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCancel,
  faChevronRight,
  faRemove,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";

// import { InternalBookingForm } from "./InternalBookingForm";

import {
  fetchCruiseExtras,
  refetchCruiseExtras,
  removeExtra,
  selectExtra,
} from "../data/groupBooking/getExtraSlice";
import BookingSteps from "../components/BookingSteps";
import Loader from "../Loader";
import { useMediaQuery } from "@mui/material";
import { fetchCheckOut } from "../data/groupBooking/checkOutSlice";
// import {
//   fectchConfirmationBooking,
//   fetchConfirmationBooking,
// } from "../../data/groupBooking/confirmationBookingSlice";
// import { fetchBookingInfo } from "../data/bookingInfoSlice";

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-left"></i>
    </div>
  );
}

// Custom Arrow for the Next Button
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-right"></i>
    </div>
  );
}
const AddExtra = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { extras, lastCruiseExtrasPayload, selectedExtras, loading, error } =
    useSelector((state) => state.cruiseExtras);

  useEffect(() => {
    if (!extras?.response?.length && lastCruiseExtrasPayload) {
      dispatch(refetchCruiseExtras())
        .unwrap()
        .catch((error) => {
          console.error("Failed to refetch cruise details:", error);
        });
    }
  }, [dispatch, lastCruiseExtrasPayload, extras?.response?.length]);

  const [checkedExtras, setCheckedExtras] = useState({});
  const handleSelectExtra = (cruiseExtraID, checked) => {
    dispatch(selectExtra({ cruiseExtraID, checked }));
    setCheckedExtras((prev) => ({
      ...prev,
      [cruiseExtraID]: checked,
    }));
  };
  const handleRemoveExtra = (cruiseExtraID) => {
    dispatch(removeExtra(cruiseExtraID));
    setCheckedExtras((prev) => ({
      ...prev,
      [cruiseExtraID]: false,
    }));
  };
  const calculateTotalPrice = () => {
    return selectedExtras.reduce(
      (total, extra) => total + extra.cruiseExtraPrice,
      0
    );
  };
  const handleBookingClick = () => {
    if (extras) {
      const payload = {
        durationID: extras?.request?.durationID,
        opreationDate: extras?.request?.opreationDate,
        numberRooms: extras?.request?.numberRooms,
        listPassengersRoomsRequests: [
          {
            adultNumber:
              extras?.request?.listPassengersRoomsRequests[0].adultNumber,
            childNumber:
              extras?.request?.listPassengersRoomsRequests[0].childNumber,
            childAges:
              extras?.request?.listPassengersRoomsRequests[0].childAges,
          },
        ],
        operatinID: extras?.request?.operatinID, // Use selected cabin's ID
        cruiseID: extras?.request?.cruiseID, // Assuming cruiseID is available in cruiseData
        cbinID: extras.request?.cbinID, // Use selected cabin's ID
        cruiseRateId: extras.request?.cruiseRateId,
        extrasIds: selectedExtras?.map((extra) => extra.cruiseExtraID),
      };

      dispatch(fetchCheckOut(payload));
      navigate("/checkout");
    }
  };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>Error</p>
      ) : (
        <Fragment>
          <Helmet>
            <title>Add Extra</title>
            <style>
              {`
          .header .header-menu .menu li a{
          color:#051036;
          }
          .header .header-menu .menu .subnav>li>a {
          color:#051036 !important;
          }
          .is-sticky.bg-dark-1{
          background-color:#fff !important;
          }
          // .is-sticky .menu li a{
          // color:#fff !important;
          // }
          .ratio-15\:9::before{
          padding-bottom:0 !important;}
          
          .form-checkbox__mark{
            width: 25px !important;
  height: 25px !important;
          }     
          `}
            </style>
          </Helmet>
          {/*banner */}
          <section className="row d-flex items-center  mt-90  ">
            <BookingSteps
              inStepThree
              one={
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
              }
              two={
                <div className="size-40 rounded-full flex-center bg-blue-1 text-16 text-white">
                  <i className="icon-check text-16 text-white"></i>
                </div>
              }
              three={
                <div className="size-40 rounded-full flex-center bg-blue-1 text-16 text-white">
                  3
                </div>
              }
              four={
                <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                  4
                </div>
              }
              five={
                <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                  5{" "}
                </div>
              }
            />
          </section>
          {/* form */}
          <section
            className="card-area section--padding my-5"
            id="cabin-details"
          >
            <div className="container">
              <div className="d-flex flex-wrap justify-content-between col-12  mx-auto">
                <div className="col-12 col-lg-7 me-lg-5">
                  <h3 className="mb-3">Select extras for your stay</h3>
                  {extras?.response?.cruiseExtras?.length ? (
                    <div className="col-xs-12 fb-results-accommodation  mb-5 ">
                      <div className="col-xs-12 d-flex justify-content-between align-items-center fb-dark-bg fb-block-header fb-results-acc-title mt-0 ">
                        <span>
                          {extras.numberOfExtras}{" "}
                          {extras.numberOfExtras > 1
                            ? "Extras Available"
                            : "Extra Available"}
                        </span>
                      </div>

                      {extras?.response?.cruiseExtras?.map((extra) => (
                        <div
                          key={extra.cruiseExtraID}
                          className="d-flex justify-content-between align-items-start px-3 py-10"
                          style={{ border: "1px solid #ccc" }}
                        >
                          {" "}
                          <div className="col-lg-3">
                            <img
                              src={extra.cruiseExtraPhoto}
                              alt={extra.cruiseExtraName}
                              loading="lazy"
                              className="me-2"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="room-content text-left me-auto border-0 pt-0 col-7">
                            <h5 className="fs-4 mt-0 pt-0">
                              {extra.cruiseExtraName}
                            </h5>

                            <div className="room-amenities-item">
                              <ul className="col-12 d-flex justify-content-between flex-wrap">
                                <p
                                  className="my-2"
                                  dangerouslySetInnerHTML={{
                                    __html: extra.cruiseExtraDescription,
                                  }}
                                />
                              </ul>
                            </div>

                            <span to="#." style={{ color: "#103682" }}>
                              <span
                                className="me-2  fw-bold col-3  "
                                style={{
                                  fontSize: "13px",
                                  color: "#000",
                                }}
                              >
                                Total Price:
                              </span>{" "}
                              <b>
                                {extra.currencyCode} {extra.cruiseExtraPrice}
                              </b>
                            </span>
                          </div>
                          <div className="   d-flex align-items-center justify-content-between position-relative">
                            <div
                              className="card-btn "
                              style={{ bottom: "unset" }}
                            >
                              <div className="form-checkbox d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  name="name"
                                  id={`select-${extra.cruiseExtraID}`}
                                  checked={
                                    checkedExtras[extra.cruiseExtraID] || false
                                  }
                                  onChange={(e) =>
                                    handleSelectExtra(
                                      extra.cruiseExtraID,
                                      e.target.checked
                                    )
                                  }
                                />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check"></div>
                                </div>
                                <div className="col-auto ms-2">
                                  <label
                                    htmlFor={`select-${extra.cruiseExtraID}`}
                                    className="text-16 text-light-1"
                                  >
                                    Select
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-danger">
                      No Extra Exist For This Cruise
                    </p>
                  )}
                </div>
                {/* Bookind Info  */}
                <div className="col-12 col-lg-4">
                  <div className="">
                    {/* Extras  */}
                    {selectedExtras?.length > 0 && (
                      <div className="form-box booking-detail-form mt-20  px-30 py-30 border-light rounded-4">
                        <div className="form-content">
                          <div className="card-item shadow-none radius-none mb-0">
                            <div className="card-body p-0">
                              <ul className="list-items list-items-2 py-0">
                                <li className="d-flex justify-content-between align-items-center mb-1">
                                  <span className="me-2 fw-bold  ">Extras</span>
                                  <span className="me-2 fw-bold">
                                    ${calculateTotalPrice()}
                                  </span>
                                </li>
                                <div className="w-full h-1 bg-border my-2 "></div>
                                {selectedExtras?.map((extra, index) => (
                                  <li
                                    key={extra.cruiseExtraID}
                                    className={`row d-flex justify-content-between align-items-center py-2 ${
                                      index % 2 === 1 && "bg-light"
                                    }`}
                                    style={{ borderBottom: "1px solid #ccc" }}
                                  >
                                    <span className="d-flex align-items-center me-2 col-7">
                                      <FontAwesomeIcon
                                        icon={faSquarePlus}
                                        style={{
                                          fontSize: "13px",
                                          color: "#103682",
                                        }}
                                      />

                                      <select
                                        // value={age}
                                        // onChange={(e) =>
                                        //   handleAgeChange(
                                        //     index,
                                        //     parseInt(e.target.value)
                                        //   )
                                        // }
                                        className="form-select text-dark text-14"
                                      >
                                        {[...Array(10)].map((_, i) => (
                                          <option
                                            className="form-select text-dark text-14"
                                            key={i + 1}
                                            value={i + 1}
                                          >
                                            {i + 1} {extra.cruiseExtraName}
                                          </option>
                                        ))}
                                      </select>
                                    </span>
                                    <span className="me-2 fw-bold col-3 text-center">
                                      {extra.currencyCode}{" "}
                                      {extra.cruiseExtraPrice}
                                      <br />
                                      <Button
                                        className="py-0 px-1 btn-danger"
                                        style={{ fontSize: "10px" }}
                                        onClick={() =>
                                          handleRemoveExtra(extra.cruiseExtraID)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faRemove} />{" "}
                                        Remove
                                      </Button>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="px-30 py-30 mt-20 border-light rounded-4">
                      <div className="row align-items-center x-gap-15 y-gap-20">
                        <div className="text-20 fw-500  mb-20">
                          Your booking details{" "}
                        </div>
                        <div className="col-auto">
                          <img
                            src={extras.response?.cruisePhoto}
                            alt={extras.response?.cruiseName}
                            loading="lazy"
                            className="size-120 rounded-4 object-cover"
                          />
                        </div>

                        <div className="col">
                          <div
                            className="d-flex x-gap-5 pb-10"
                            dangerouslySetInnerHTML={{
                              __html: extras.response?.curiseStarCode,
                            }}
                          />

                          <div className="text-20 lh-17 fw-500">
                            {extras.response?.cruiseName}{" "}
                          </div>
                          <div className="text-14 lh-15 mt-5">
                            {extras.response?.itineraryName}{" "}
                          </div>

                          {/* <div className="row x-gap-10 y-gap-10 items-center pt-10">
                            <div className="col-auto">
                              <div className="d-flex items-center">
                                <div className="size-30 flex-center bg-blue-1 rounded-4">
                                  <div className="text-12 fw-600 text-white">
                                    4.8{" "}
                                  </div>
                                </div>

                                <div className="text-14 fw-500 ml-10">
                                  Exceptional{" "}
                                </div>
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="text-14">3,014 reviews </div>
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <div className="border-top-light mt-30 mb-20"></div>

                      <div className="row y-gap-20 justify-between">
                        <div className="col-auto">
                          <div className="text-15">Check-in </div>
                          <div className="fw-500">
                            {extras.response?.itineraryFrom}
                          </div>
                          {/* <div className="text-15 text-light-1">
                            15:00 – 23:00{" "}
                          </div> */}
                        </div>

                        <div className="col-auto md:d-none">
                          <div className="h-full w-1 bg-border"></div>
                        </div>

                        <div className="col-auto text-right md:text-left">
                          <div className="text-15">Check-out </div>
                          <div className="fw-500">
                            {extras.response?.itineraryTo}
                          </div>
                          {/* <div className="text-15 text-light-1">
                            01:00 – 11:00{" "}
                          </div> */}
                        </div>
                      </div>
                      <div className="border-top-light mt-30 mb-20"></div>

                      <div className="row y-gap-20 justify-between items-center">
                        <div className="col-auto">
                          <div className="text-15">You selected: </div>
                          <div className="fw-500">Superior Double Studio </div>
                          <Link
                            to="/cruises-details#rooms"
                            className="text-15 text-blue-1 underline"
                          >
                            Change your selection{" "}
                          </Link>
                        </div>

                        <div className="col-auto">
                          <div className="text-15">1 room, 4 adult </div>
                        </div>
                      </div>
                    </div>

                    <div className="px-30 py-30 border-light rounded-4 mt-30">
                      <div className="text-20 fw-500 mb-20">
                        Your price summary{" "}
                      </div>
                      {extras.response?.taxes?.taxs?.map((item, index) => (
                        <div
                          key={index}
                          className="row y-gap-5 justify-between"
                        >
                          <div className="col-auto">
                            <div className="text-15">{item.taxName} </div>
                          </div>
                          <div className="col-auto">
                            <div className="text-15">${item.taxValue} </div>
                          </div>
                        </div>
                      ))}

                      <div className="px-20 py-20 bg-blue-2 rounded-4 mt-20">
                        <div className="row y-gap-5 justify-between">
                          <div className="col-auto">
                            <div className="text-18 lh-13 fw-500">Price </div>
                          </div>
                          <div className="col-auto">
                            <div className="text-18 lh-13 fw-500">
                              US$ {extras.response?.totalPrice}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div
                    className="row  mt-3 text-center"
                    style={{ bottom: "unset" }}
                  >
                    <button
                      type="button"
                      onClick={handleBookingClick}
                      className="button h-60 px-24 -dark-1 bg-blue-1 text-white"
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddExtra;
