import React, { Fragment, useEffect, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DatePicker, Drawer, SelectPicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format, isBefore } from "date-fns";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Alert, Snackbar, useMediaQuery, TextField } from "@mui/material";
import { fetchCruiseListBooking } from "../data/groupBooking/cruiseListSlice";
// import Loader from "../Loader";
// import { TimePicker } from "rsuite";
import { useFormik } from "formik"; // Import Formik
import * as Yup from "yup"; // Import Yup

export const BookingForm = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1023px)");
  const isDesktop = useMediaQuery("(min-width:1024px)");

  // You can also use them in combination
  const isSmallScreen = useMediaQuery("(max-width:1023px)"); // Both mobile and tablet

  const cursorStyle = {
    cursor: `url('/logo1.png') 25 25, auto`, // 25 25 represents the cursor hotspot position
  };
  const navigate = useNavigate();

  const [selectedTime, setSelectedTime] = useState(""); // Add state for selected time
  const [passengers, setPassengers] = useState(1);
  const [foreigner, setForeigner] = useState(
    localStorage.getItem("foreigner")
      ? Number(localStorage.getItem("foreigner"))
      : 1
  );
  const [egyptian, setEgyptian] = useState(
    localStorage.getItem("egyptian")
      ? Number(localStorage.getItem("egyptian"))
      : 0
  );
  useEffect(() => {
    setPassengers(foreigner + egyptian);
  }, [foreigner, egyptian]);

  const handleIncrement = (setter) => {
    setter((prev) => prev + 1);
  };
  // const handleChildIncrement = (setter) => {
  //   setter((prev) => (prev < 2 ? prev + 1 : prev));
  // };

  const handleDecrement = (setter) => {
    setter((prev) => (prev >= 0 ? prev - 1 : 0));
  };

  const [isActiveGuest, setIsActiveGuest] = useState(false);

  const GuestMenuRef = useRef(null);

  // Toggle the active state on click
  const handleGuestClick = () => {
    setIsActiveGuest(!isActiveGuest);
  };
  const handleGuestBlur = (event) => {
    if (
      GuestMenuRef.current &&
      !GuestMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveGuest(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(date, "EEE d MMM"); // e.g., "Wed 2 Mar"
  };
  const disablePastDates = (date) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0); // Disable past dates
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // 'error' | 'warning' | 'info' | 'success'
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const validationSchema = Yup.object({
    date: Yup.date().required("Date is required"),
    // timeSlot: Yup.string().required("Time slot is required"),

    // Add more validations as needed
  });
  const formik = useFormik({
    initialValues: {
      date: new Date(),

      // Add more initial values as needed
    },
    validationSchema: validationSchema,
    onSubmit: (e) => {
      localStorage.setItem("passengers", passengers);
      localStorage.setItem("foreigner", foreigner);
      localStorage.setItem("egyptian", egyptian);
      localStorage.setItem("date", formatDate(formik.values.date));
      navigate("/booking-balloon");
    },
  });
  return (
    <div className="tabs -underline mt-60 js-tabs is-in-view">
      <div className="tabs__content mt-30 md:mt-20 js-tabs-content">
        <div className="tabs__pane -tab-item-1 is-tab-el-active">
          {!isMobile ? (
            <div className="mainSearch -w-1000 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
              <form
                onSubmit={formik.handleSubmit}
                className="button-grid items-center"
              >
                <div className="searchMenu-date px-10 lg:py-20 lg:px-0 -is-dd-wrap-active">
                  <div>
                    <h4 className="text-15 fw-500 ls-2 lh-16">Date:</h4>
                    <DatePicker
                      value={formik.values.date}
                      onChange={(date) => {
                        formik.setFieldValue("date", date);
                      }}
                      placeholder={"Select Date"}
                      className="text-15 text-light-1 ls-2 lh-16"
                      format="MM/dd/yyyy"
                      defaultValue={new Date()}
                      renderValue={(value) => {
                        return format(value, "EEE, d MMM");
                      }}
                      oneTap
                      shouldDisableDate={(date) => {
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        return date < today;
                      }}
                      renderCell={(date) => {
                        const day = date.getDate();
                        const month = date.getMonth();
                        const weekday = date.getDay();

                        return day;
                      }}
                    />

                    {formik.errors.date && (
                      <p className="text-danger text-14 text-center">
                        {formik.errors.date}
                      </p>
                    )}
                  </div>
                </div>{" "}
                <div
                  ref={GuestMenuRef}
                  style={{ borderRight: "1px solid #dddddd" }}
                  className={`searchMenu-guests px-30 lg:py-20 lg:px-0 js-form-dd js-form-counters ${
                    isActiveGuest ? "-is-dd-wrap-active" : ""
                  }`}
                  onBlur={handleGuestBlur}
                >
                  <div
                    data-x-dd-click="searchMenu-guests"
                    onClick={handleGuestClick}
                    value={formik.values.passengers}
                    onChange={(passengers) => {
                      formik.setFieldValue("passengers", passengers);
                    }}
                  >
                    <h4 className="text-15 fw-500 ls-2 lh-16">Passengers</h4>
                    <div className="text-15 text-light-1 ls-2 lh-16">
                      <span className="js-count-adult">{passengers}</span>{" "}
                      {passengers > 1 ? "Passengers" : "Passenger"} (
                      <span className="js-count-child">{foreigner}</span>{" "}
                      Foreigner -{" "}
                      <span className="js-count-room">{egyptian} </span>{" "}
                      Egyptian )
                      <FontAwesomeIcon
                        style={{ float: "right" }}
                        className="  text-primary"
                        icon={faAngleDown}
                      />
                    </div>{" "}
                  </div>

                  <div
                    className={`searchMenu-guests__field shadow-2  ${
                      isActiveGuest ? "-is-active" : ""
                    }`}
                  >
                    {" "}
                    <div className="bg-white px-30 py-30 rounded-4">
                      <div className="row y-gap-10 justify-between items-center">
                        <div className="col-auto">
                          <div className="text-15 fw-500">Foreigner</div>
                        </div>

                        <div className="col-auto">
                          <div className="d-flex items-center js-counter">
                            <button
                              type="button"
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                              onClick={() =>
                                foreigner > 0 &&
                                passengers > 1 &&
                                handleDecrement(setForeigner)
                              }
                            >
                              <i className="icon-minus text-12"></i>
                            </button>

                            <div className="flex-center size-20 ml-15 mr-15">
                              <div className="text-15">{foreigner}</div>
                            </div>

                            <button
                              type="button"
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                              onClick={() => handleIncrement(setForeigner)}
                            >
                              <i className="icon-plus text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="border-top-light mt-24 mb-24"></div>
                      <div className="row y-gap-10 justify-between items-center">
                        <div className="col-auto">
                          <div className="text-15 fw-500">Egyptian</div>
                        </div>

                        <div className="col-auto">
                          <div className="d-flex items-center js-counter">
                            <button
                              type="button"
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                              onClick={() =>
                                egyptian > 0 &&
                                passengers > 1 &&
                                handleDecrement(setEgyptian)
                              }
                            >
                              <i className="icon-minus text-12"></i>
                            </button>

                            <div className="flex-center size-20 ml-15 mr-15">
                              <div className="text-15">{egyptian}</div>
                            </div>

                            <button
                              type="button"
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                              onClick={() => handleIncrement(setEgyptian)}
                            >
                              <i className="icon-plus text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="bg-white pb-1 "
                      style={{
                        borderBottom: "0px dashed #BB4929",
                        borderLeft: "0px dashed #BB4929",
                        borderRight: "0px dashed #BB4929",
                      }}
                    >
                      {" "}
                      <Button
                        className="mx-auto  text-center d-block"
                        onClick={() => setIsActiveGuest(false)}
                      >
                        <FontAwesomeIcon
                          className="mx-2 my-1"
                          icon={faAngleUp}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="button-item  pl-30">
                  <button
                    type="submit"
                    className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="icon-search text-20 mr-10"></i>
                    Check Availability
                  </button>
                </div>{" "}
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={5000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                  >
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
              </form>
            </div>
          ) : (
            <div className="col-12 booking-mobile">
              {" "}
              <Drawer
                anchor="bottom"
                placement="bottom"
                size="full"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                // PaperProps={{
                //   sx: {
                //     width: isMobile ? "100%" : "80%",

                //     padding: "20px",
                //   },
                // }}
              >
                <Drawer.Header className="ps-0  ">
                  <Drawer.Title className="ps-2   text-primary fw-bold">
                    <img
                      src="logo1.png"
                      alt="logo"
                      className="header-logo mr-20"
                    />
                  </Drawer.Title>
                </Drawer.Header>

                <div className="mainSearch col-10 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
                  <form
                    onSubmit={formik.handleSubmit}
                    className="button-grid items-center"
                  >
                    <div className="searchMenu-date px-10 lg:py-20 lg:px-0 -is-dd-wrap-active">
                      <div>
                        <h4 className="text-15 fw-500 ls-2 lh-16">Date:</h4>
                        <DatePicker
                          value={formik.values.date}
                          onChange={(date) => {
                            formik.setFieldValue("date", date);
                          }} // Update date
                          placeholder={"Select Date"}
                          className="text-15 text-light-1 ls-2 lh-16"
                          format="MM/dd/yyyy"
                          defaultValue={new Date()}
                          renderValue={(value) => {
                            return format(value, "EEE, d MMM");
                          }}
                          oneTap
                          shouldDisableDate={(date) => {
                            const today = new Date();
                            today.setHours(0, 0, 0, 0);
                            return date < today;
                          }}
                          renderCell={(date) => {
                            const day = date.getDate();
                            const month = date.getMonth();
                            const weekday = date.getDay();

                            return day;
                          }}
                        />

                        {formik.errors.date && (
                          <p className="text-danger text-14 text-center">
                            {formik.errors.date}
                          </p>
                        )}
                      </div>
                    </div>{" "}
                    <div
                      ref={GuestMenuRef}
                      style={{ borderRight: "1px solid #dddddd" }}
                      className={`searchMenu-guests px-30 lg:py-20 lg:px-0 js-form-dd js-form-counters ${
                        isActiveGuest ? "-is-dd-wrap-active" : ""
                      }`}
                      onBlur={handleGuestBlur}
                    >
                      <div
                        data-x-dd-click="searchMenu-guests"
                        onClick={handleGuestClick}
                      >
                        <h4 className="text-15 fw-500 ls-2 lh-16">
                          Passengers
                        </h4>
                        <div className="text-15 text-light-1 ls-2 lh-16">
                          <span className="js-count-adult">{passengers}</span>{" "}
                          Passengers (
                          <span className="js-count-child">{foreigner}</span>{" "}
                          Foreigner -
                          <span className="js-count-room">{egyptian}</span>{" "}
                          Egyptian ){/* <i className="icon-man  " ></i> */}
                          <FontAwesomeIcon
                            style={{ float: "right" }}
                            className="text-primary"
                            icon={faAngleDown}
                          />
                        </div>{" "}
                      </div>

                      <div
                        className={`searchMenu-guests__field shadow-2  ${
                          isActiveGuest ? "-is-active" : ""
                        }`}
                      >
                        {" "}
                        <div className="bg-white px-30 py-30 rounded-4">
                          <div className="row y-gap-10 justify-between items-center">
                            <div className="col-auto">
                              <div className="text-15 fw-500">Foreigner</div>
                            </div>

                            <div className="col-auto">
                              <div className="d-flex items-center js-counter">
                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                  onClick={() =>
                                    foreigner > 1 &&
                                    handleDecrement(setForeigner)
                                  }
                                >
                                  <i className="icon-minus text-12"></i>
                                </button>

                                <div className="flex-center size-20 ml-15 mr-15">
                                  <div className="text-15">{foreigner}</div>
                                </div>

                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                  onClick={() => handleIncrement(setForeigner)}
                                >
                                  <i className="icon-plus text-12"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="border-top-light mt-24 mb-24"></div>
                          <div className="row y-gap-10 justify-between items-center">
                            <div className="col-auto">
                              <div className="text-15 fw-500">Egyptian</div>
                            </div>

                            <div className="col-auto">
                              <div className="d-flex items-center js-counter">
                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                  onClick={() =>
                                    egyptian > 1 && handleDecrement(setEgyptian)
                                  }
                                >
                                  <i className="icon-minus text-12"></i>
                                </button>

                                <div className="flex-center size-20 ml-15 mr-15">
                                  <div className="text-15">{egyptian}</div>
                                </div>

                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                  onClick={() => handleIncrement(setEgyptian)}
                                >
                                  <i className="icon-plus text-12"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="bg-white pb-1 "
                          style={{
                            borderBottom: "0px dashed #BB4929",
                            borderLeft: "0px dashed #BB4929",
                            borderRight: "0px dashed #BB4929",
                          }}
                        >
                          {" "}
                          <Button
                            className="mx-auto  text-center d-block"
                            onClick={() => setIsActiveGuest(false)}
                          >
                            <FontAwesomeIcon
                              className="mx-2 my-1"
                              icon={faAngleUp}
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="button-item  pl-30">
                      <button
                        type="submit"
                        className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white"
                        style={{ textDecoration: "none" }}
                      >
                        <i className="icon-search text-20 mr-10"></i>
                        Check Availability
                      </button>
                    </div>{" "}
                    <Snackbar
                      open={openSnackbar}
                      autoHideDuration={5000}
                      onClose={handleCloseSnackbar}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                      <Alert
                        onClose={handleCloseSnackbar}
                        severity={snackbarSeverity}
                        sx={{ width: "100%" }}
                      >
                        {snackbarMessage}
                      </Alert>
                    </Snackbar>
                  </form>
                </div>
              </Drawer>
              <button
                className="fixed-bottom mx-auto text-18 py-5 text-center d-block"
                style={{
                  position: "fixed",
                  padding: " 40px 0",
                  bottom: "0",
                  left: "0",
                  height: "60px",
                  borderRadius: "30px 30px 0 0 ",
                  width: "100%",
                  backgroundColor: "#BB4929",
                  color: "white",
                }}
                onClick={() => setDrawerOpen(true)}
              >
                <i className="icon-search  mr-10"></i>
                Check Availability
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
