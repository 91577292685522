// itinerarySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "./url";

export const fetchDurations = createAsyncThunk(
  "durations/fetchDurations",
  async (rejectWithValue) => {
    try {
      const response = await axios.get(
        `https://api.nilecabins.info/be/getDurations`
      );
      return response.data; // Assuming the API response format is { data: [...] }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const durationsSlice = createSlice({
  name: "durations",
  initialState: {
    durations: [],
    loading: false, // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDurations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDurations.fulfilled, (state, action) => {
        state.loading = false;
        state.durations = action.payload;
      })
      .addCase(fetchDurations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default durationsSlice.reducer;
